import { Divider, Grid, Hidden } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tracklist: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

const HomeSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container justifyContent={'space-between'} spacing={3}>
        <Grid item xs>
          <Skeleton className={classes.title} variant='text' height='5rem' animation='wave' />
          <Skeleton style={{ marginBottom: '1em' }} variant='text' animation='wave' />
          <Divider />
        </Grid>
        <Hidden smDown>
          <Grid item xs md={6} lg={5}>
            <Skeleton variant='rect' animation='wave' height={175} />
          </Grid>
        </Hidden>
      </Grid>
      <Grid container elevation={0} spacing={3} className={classes.tracklist}>
        <Grid item md={4} sm={4} xs={12}>
          <>
            <Skeleton variant='rect' animation='wave' height={350} />
          </>
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <>
            <Skeleton variant='rect' animation='wave' height={300} />
          </>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.text}>
        <Skeleton variant='text' animation='wave' />
      </div>
    </>
  );
};

export default HomeSkeleton;
