import * as React from "react";
import { Divider, Hidden, IconButton, Typography } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import LinkButton from "./LinkButton";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: theme.palette.text.primary,
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    titleDiv: {
        marginLeft: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    },
}));

const SideDrawer = ({ navLinks, lang, setLang }) => {
    const classes = useStyles();
    const location = useLocation();
    const [loc, setLoc] = React.useState("");
    const [title, setTitle] = React.useState(loc.split("/"));
    React.useEffect(() => {
        setLoc(location.pathname);
    }, [location.pathname]);

    React.useEffect(() => {
        setTitle(loc.split("/"));
    }, [loc]);

    const findGermanTitle = (title) => {
        for (var i = 0; i < navLinks.length; i++) {
            if (title === navLinks[i].titleEN) {
                return navLinks[i].titleDE;
            }
        }
    };

    const [state, setState] = useState({ right: false });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ [anchor]: open });
    };
    const sideDrawerList = (anchor) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component="nav">
                {navLinks.map(({ titleDE, titleEN, path }) => (
                    <a href={path} key={titleDE} className={classes.linkText}>
                        <ListItem button>
                            {lang === "de" ? (
                                <LinkButton
                                    currentpath={loc}
                                    title={titleDE}
                                    path={path}
                                    classes={classes}
                                />
                            ) : (
                                <LinkButton
                                    currentpath={loc}
                                    title={titleEN}
                                    path={path}
                                    classes={classes}
                                />
                            )}
                        </ListItem>
                    </a>
                ))}
                <Divider />
                <LanguageSelect lang={lang} setLang={(x) => setLang(x)} />
            </List>
        </div>
    );

    return (
        <React.Fragment>
            <Hidden xsDown>
                {typeof title[2] !== undefined && (
                    <div
                        className={classes.titleDiv}
                        onClick={toggleDrawer("right", true)}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            className={classes.linkText}
                            style={{ paddingTop: "2px" }}
                        >
                            {lang === "de"
                                ? findGermanTitle(title[2])
                                : title[2]}
                        </Typography>
                    </div>
                )}
            </Hidden>
            <IconButton
                edge="end"
                aria-label="menu"
                onClick={toggleDrawer("right", true)}
            >
                <Menu fontSize="large" />
            </IconButton>
            <Drawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer("right", false)}
            >
                {sideDrawerList("right")}
            </Drawer>
        </React.Fragment>
    );
};

export default SideDrawer;
