import * as React from "react";
import { Zoom, useScrollTrigger } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(10),
        right: theme.spacing(2),
    },
}));

const ScrollTop = ({ children }) => {
    const classes = useStyles();
    const trigger = useScrollTrigger();
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            "#back-to-top-anchor"
        );
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };
    return (
        <Zoom in={trigger}>
            <div
                onClick={handleClick}
                role="presentation"
                className={classes.root}
                style={{ zIndex: "99" }}
            >
                {children}
            </div>
        </Zoom>
    );
};
export default ScrollTop;
