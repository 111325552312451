import React from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    Fab,
    makeStyles,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Header from "./header/Header";
import HideOnScroll from "./header/HideOnScroll";
import Footer from "./footer/Footer";
import { Organisation, PageContent } from ".";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.background.default,
    },
}));

export default function Root({ lang, setLang, themeMode }) {
    const [state, setState] = React.useState(true);
    const [forceOpen, setForceOpen] = React.useState(false);
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <div style={{ position: "relative", minHeight: "100vh" }}>
                <HideOnScroll>
                    <AppBar className={classes.appBar}>
                        <Header lang={lang} setLang={(x) => setLang(x)} />
                    </AppBar>
                </HideOnScroll>
                <Toolbar id="back-to-top-anchor" />
                <PageContent
                    lang={lang}
                    cookiedisk={(x) => setState(x)}
                    callCookies={(bool) => setForceOpen(bool)}
                    mounted={state}
                    themeMode={themeMode}
                />
                <Footer
                    lang={lang}
                    callCookies={(bool) => setForceOpen(bool)}
                    themeMode={themeMode}
                />
            </div>
            <Organisation.ConsentDrawer
                state={state}
                setState={(x) => setState(x)}
                lang={lang}
                setLang={(x) => setLang(x)}
                forceOpen={forceOpen}
                setForceOpen={(x) => setForceOpen(x)}
            />
            <Organisation.ScrollTop>
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="scroll back to top"
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </Organisation.ScrollTop>
        </React.Fragment>
    );
}
