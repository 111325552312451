import * as React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
    },
    quote: {
        fontStyle: "italic",
    },
}));

const Quote = ({ quote }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="body1" component="p" className={classes.quote}>
                — {quote}
            </Typography>
        </div>
    );
};
export default Quote;
