import React, { useState } from 'react';
import { Typography, Grid, Divider, Hidden, Drawer, Card } from '@material-ui/core';
import { RichText, CookieAlert } from '../organisation';
import { SoundCloud, Image } from '../organisation';
import InteractivePlayer from '../music/InteractivePlayer';
import SmallInteractivePlayer from '../music/SmallInteractivePlayer';
import { makeStyles } from '@material-ui/core/styles';
import Youtube from '../organisation/Youtube-three';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Newsroom from './Newsroom';
import HomeSkeleton from './HomeSkeleton';

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tracklist: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(2),
  },
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  cover: {
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  drawer: {
    borderTop: '0px',
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
}));

function Album({ home, isFetching, lang, callCookies }) {
  const [play, setPlay] = useState(false);
  let match = useRouteMatch();
  const [cookies] = useCookies(['consent']);
  const [cookieAlertOpen, setCookieAlertOpen] = React.useState(false);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {isFetching ? (
        <HomeSkeleton />
      ) : (
        <>
          {home ? (
            <div id='top-of-home-anchor'>
              <Grid container justifyContent={'space-between'} spacing={3}>
                <Grid item xs>
                  <Typography
                    variant='h2'
                    style={{
                      fontSize: '3.5rem',
                      textAlign: 'left',
                      paddingBottom: '8px',
                    }}
                  >
                    {home.fields.title}
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      textAlign: 'left',
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    {home.fields.subtitle}
                  </Typography>
                  <Divider />
                </Grid>
                {home.fields.soundCloud ? (
                  <Hidden smDown>
                    <Grid item xs md={6} lg={5}>
                      <InteractivePlayer
                        image={home.fields.songCover.fields.file.url}
                        title={home.fields.songTitle}
                        artist={home.fields.songKuenstler}
                        button={cookies.consent === '1' || cookies.consent === '3' ? () => setPlay(!play) : () => setCookieAlertOpen(true)}
                        playing={play}
                        url={home.fields.soundCloud}
                      />
                    </Grid>
                  </Hidden>
                ) : null}
              </Grid>
              {home.fields.cover ? (
                <Grid container elevation={0} spacing={3} className={classes.tracklist}>
                  <Grid item md={5} sm={12} xs={12}>
                    <>
                      <Link to={`/${match.path.split('/')[1]}/music/${home.fields.path}`}>
                        <Card elevation={0}>
                          <div className={classes.cover}>
                            <Image url={home.fields.cover.fields.file.url} alt={home.fields.title} lazy wM={700} wT={500} />
                          </div>
                          {/* <CardMedia
                            className={classes.cover}
                            component="img"
                            image={'https://' + home.fields.cover.fields.file.url}
                            title={home.fields.title}
                            /> */}
                        </Card>
                      </Link>
                    </>
                  </Grid>
                  <Grid item md={7} sm={12} xs={12}>
                    <>
                      <RichText richText={home.fields.firstText} />
                    </>
                  </Grid>
                </Grid>
              ) : (
                <Grid container elevation={0} spacing={3} className={classes.tracklist}>
                  <Grid item xs={12}>
                    <>
                      <RichText richText={home.fields.firstText} />
                    </>
                  </Grid>
                </Grid>
              )}

              {/* Aktuelles */}
              <Divider className={classes.gutterBottom} />
              {<Newsroom lang={lang} callCookies={callCookies} />}

              {home.fields.youtube ? (
                <>
                  <Divider />
                  <Youtube videos={home.fields.youtube} lang={lang} callCookies={(bool) => callCookies(bool)} />
                </>
              ) : null}

              {home.fields.artists ? (
                <>
                  <Divider />
                  <div className={classes.text}>
                    <RichText richText={home.fields.artists} />
                  </div>
                </>
              ) : null}

              {home.fields.secondText ? (
                <>
                  <Divider />
                  <div className={classes.text}>
                    <RichText richText={home.fields.secondText} />
                  </div>
                </>
              ) : null}

              {home.fields.soundCloud ? (
                <>
                  {cookies.consent === '1' || cookies.consent === '3' ? (
                    <SoundCloud url={home.fields.soundCloud} playing={play} />
                  ) : (
                    <CookieAlert
                      cookieAlertOpen={cookieAlertOpen}
                      setCookieAlertOpen={(bool) => setCookieAlertOpen(bool)}
                      callCookies={(bool) => callCookies(bool)}
                      lang={lang}
                    />
                  )}
                </>
              ) : null}
              {home.fields.soundCloud ? (
                <>
                  <Hidden smUp>
                    <Drawer
                      classes={{
                        paperAnchorDockedBottom: classes.drawer,
                      }}
                      variant='permanent'
                      anchor='bottom'
                    >
                      <SmallInteractivePlayer
                        image={home.fields.songCover.fields.file.url}
                        title={home.fields.songTitle}
                        artist={home.fields.songKuenstler}
                        button={cookies.consent === '1' || cookies.consent === '3' ? () => setPlay(!play) : () => setCookieAlertOpen(true)}
                        playing={play}
                        url={home.fields.soundCloud}
                        view={false}
                      />
                    </Drawer>
                  </Hidden>
                  <Hidden only={['xs', 'md', 'lg', 'xl']}>
                    <Drawer
                      classes={{
                        paperAnchorDockedBottom: classes.drawer,
                      }}
                      variant='permanent'
                      anchor='bottom'
                      elevation={12}
                    >
                      <SmallInteractivePlayer
                        image={home.fields.songCover.fields.file.url}
                        title={home.fields.songTitle}
                        artist={home.fields.songKuenstler}
                        button={cookies.consent === '1' || cookies.consent === '3' ? () => setPlay(!play) : () => setCookieAlertOpen(true)}
                        playing={play}
                        url={home.fields.soundCloud}
                        view={true}
                      />
                    </Drawer>
                  </Hidden>
                </>
              ) : null}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
export default Album;
