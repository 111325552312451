import React, { useState, useEffect } from "react";
import About from "./About";
import getAPIData from "../../api/getAPIDataNoSetState";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/Ueber-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/Ueber-Kopfbild-s.jpg'

export default function AboutRoot({ lang }) {
    const [state, setState] = useState({
        about: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        getAPIData(
            "about",
            lang,
            (a, b) => 0,
            (response) => setState({ about: response })
        );
    }, [lang]);
    return (
        <>
            <EvaluateContent
                source={"Ueber-Kopfbild.jpg"}
                bodycomp={
                    <About
                        about={state.about.data[0]}
                        isFetching={state.about.isFetching}
                        lang={lang}
                    />
                }
            />
        </>
    );
}
