import React, { useState, useEffect } from "react";
import getAPIData from "../../api/getAPIDataNoSetState";
import News from "./News";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/News-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/News-Kopfbild-s.jpg'

export default function NewsRoot({ lang, callCookies }) {
    const [state, setState] = useState({
        news: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        const newsSort = (a, b) => {
            if (a.fields.old === true && b.fields.old === true)
                return new Date(b.fields.date) - new Date(a.fields.date);
            if (a.fields.old === true)
                return new Date(b.sys.createdAt) - new Date(a.fields.date);
            if (b.fields.old === true)
                return new Date(b.fields.old) - new Date(a.sys.createdAt);
            return new Date(b.sys.createdAt) - new Date(a.sys.createdAt);
        };
        getAPIData(
            "news",
            lang,
            (a, b) => newsSort(a, b),
            (response) => setState({ news: response })
        );
    }, [lang]);

    // console.log(state.news.data)
    return (
        <>
            <EvaluateContent
                source={"News-Kopfbild.jpg"}
                bodycomp={
                    <News
                        news={state.news.data}
                        isFetching={state.news.isFetching}
                        lang={lang}
                        callCookies={(bool) => callCookies(bool)}
                    />
                }
            />
        </>
    );
}
