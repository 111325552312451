import * as React from "react";
import { Grid } from "@material-ui/core";
// import Pagination from './Pagination';
import New from "./New";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

const NewsGrid = ({ news, page, setPage, lang, callCookies }) => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={3} className={classes.root}>
                {news.slice((page - 1) * 6, page * 6).map((article) => (
                    <Grid
                        key={article.fields.title}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ height: "auto" }}
                    >
                        <New
                            key={article.sys.createdAt}
                            data={article}
                            lang={lang}
                            callCookies={(bool) => callCookies(bool)}
                        />
                    </Grid>
                ))}
            </Grid>
            {/* <Pagination count={news.length} page={page} setPage={(num) => setPage(num)}/> */}
        </>
    );
};
export default NewsGrid;
