import * as React from "react";
import ReactPlayer from "react-player/youtube";
import { Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import "./youtube.css";
import placeholder_de from "./Platzhalter Youtube web 21 de.jpg";
import placeholder_en from "./Platzhalter Youtube web 21 en.jpg";

const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        height: "0",
        position: "relative",
        paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
        // borderRadius: '4px',
        // overflow: 'hidden',
    },
}));

class ResponsivePlayer extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                {this.props.url && (
                    <div className={classes.playerWrapper}>
                        <ReactPlayer
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                            }}
                            className={classes.reactPlayer}
                            url={this.props.url}
                            width="100.3%" //fix for broken rounded edges
                            height="100.3%"
                            config={{
                                youtube: {
                                    playerVars: {
                                        controls: 1,
                                        origin:
                                            "https://www.johannesmoessinger.com",
                                    },
                                },
                            }}
                        />
                    </div>
                )}
            </>
        );
    }
}

const Player = ({ url, fallbackImage, lang, callCookies, noButton }) => {
    const classes = useStyles();
    const [cookies] = useCookies(["consent"]);
    // -webkit-mask-image: -webkit-radial-gradient(white, black);
    return (
        <Card
            className="card"
            variant="elevation"
            style={{ overflow: "hidden" }}
        >
            {/* {typeof cookies.consent !== 'undefined' ? <ResponsivePlayer url={url} classes={classes}/> : null } */}
            {cookies.consent === "1" || cookies.consent === "3" ? (
                <ResponsivePlayer url={url} classes={classes} />
            ) : (
                <div className={classes.playerWrapper}>
                    <img
                        style={{
                            width: "100%",
                            height: "auto",
                            position: "absolute",
                            top: "0",
                            left: "0",
                        }}
                        src={
                            noButton
                                ? fallbackImage
                                : lang === "de"
                                ? placeholder_de
                                : placeholder_en
                        }
                        alt="Youtube Placeholder"
                    />
                    {!noButton && (
                        <div
                            style={{
                                position: "absolute",
                                top: "0.5rem",
                                width: "100%",
                            }}
                        >
                            <Button
                                onClick={() => callCookies(true)}
                                color="secondary"
                                variant="contained"
                                size="large"
                                style={{
                                    textAlign: "right",
                                    position: "absolute",
                                    right: "0.5rem",
                                }}
                            >
                                {lang === "de"
                                    ? "Cookie Einstellungen Öffnen"
                                    : "Open Cookie Settings"}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </Card>
    );
};
export default Player;
