import { Skeleton } from "@material-ui/lab";
import * as React from "react";
import HeaderImage from "./HeaderImage";

const EvaluateContent = ({
    source,
    bodycomp,
    contentful,
    sourceS,
    sourceWebpB,
    sourceWebpS,
}) => {
    return (
        <>
            <div
                style={{
                    height: "0",
                    overflow: "hidden",
                    paddingTop: "41.57052%",
                    position: "relative",
                }}
            >
                {source ? (
                    // ?   <img
                    //         style={{
                    //             width: '100%',
                    //             height: 'auto',
                    //             position: 'absolute',
                    //             top: '0',
                    //             left: '0',
                    //             MozUserSelect: "none",
                    //             WebkitUserSelect: "none",
                    //             msUserSelect: "none"
                    //         }}
                    //         src={source}
                    //         alt="Kopfbild Johannes Mössinger"
                    //     />
                    <HeaderImage
                        source={source}
                        alt={"Kopfbild Johannes Mössinger"}
                        contentful={contentful}
                        sourceS={sourceS}
                        sourceWebpB={sourceWebpB}
                        sourceWebpS={sourceWebpS}
                    />
                ) : (
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
            </div>
            {bodycomp}
        </>
    );
};
export default EvaluateContent;
