import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        minWidth: 0,
        width: "100%",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    details: {
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        width: "100%",
    },
    content: {
        display: "block",
        width: "100%",
        overflow: "hidden",
    },
    cover: {
        width: 154,
        height: 154,
        flexShrink: 0,
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    icon: {
        height: 38,
        width: 38,
    },
}));

export default function MediaControlCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root} raised={true}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography
                        style={{ fontSize: "1.27rem" }}
                        component="h5"
                        variant="h5"
                        disply="block"
                        noWrap
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        style={{ fontSize: "0.9rem" }}
                        variant="subtitle1"
                        color="textSecondary"
                        disply="block"
                        noWrap
                    >
                        {props.artist}
                    </Typography>
                </CardContent>
                <div className={classes.controls}>
                    <IconButton
                        aria-label="play/pause"
                        onClick={() => props.button()}
                    >
                        {props.playing ? (
                            <PauseIcon className={classes.icon} />
                        ) : (
                            <PlayArrowIcon className={classes.icon} />
                        )}
                    </IconButton>
                    <Button onClick={() => window.open(props.url, "_blank")}>
                        Soundcloud
                    </Button>
                </div>
            </div>
            <CardMedia
                className={classes.cover}
                image={"https://" + props.image + "?w=462&h=462"}
            />
        </Card>
    );
}
