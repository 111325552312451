import React, { useState } from "react";
import "./App.css";
import { Root } from "./components";
import { ThemeProvider } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import theme from "./Theme";

function App() {
    const { pathname } = useLocation();
    const [, locale] = pathname.split("/");

    const localeCheck = (locale) => {
        if (locale === "de" || locale === "en") return locale;
        else return "de";
    };
    const [lang, setLang] = useState(localeCheck(locale));

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Root lang={lang} setLang={(x) => setLang(x)} themeMode={theme.palette.type}/>
            </div>
        </ThemeProvider>
    );
}

export default App;
