import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "1600px",
        paddingLeft: "5vw",
        paddingRight: "5vw",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

export default function LinearIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress color="secondary" />
        </div>
    );
}
