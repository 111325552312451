import * as React from "react";
import PageStyle from "../organisation/PageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import RichText from "../organisation/RichText";
import Skeleton from "@material-ui/lab/Skeleton";
import CookieTable from "./CookieTable";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(4),
        fontSize: "3rem",
    },
}));

const Datenschutz = ({ data, isFetching, lang }) => {
    const classes = useStyles();
    return (
        <PageStyle>
            <div className={classes.root}>
                <>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={classes.title}
                    >
                        {!isFetching && data ? (
                            data.fields.title
                        ) : (
                            <Skeleton animation="wave" />
                        )}
                    </Typography>

                    <CookieTable lang={lang} />
                    <>
                        {!isFetching && data ? (
                            <RichText richText={data.fields.text} />
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                height={500}
                            />
                        )}
                    </>
                </>
            </div>
        </PageStyle>
    );
};
export default Datenschutz;
