import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    Button,
    TextField,
    CardHeader,
    CardContent,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: "auto",
        marginRight: "auto",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        background: theme.palette.type === "dark" ? grey[900] : grey[200], //changed from 300
        maxWidth: "500px",
    },
    item: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        margin: "auto",
    },
    headerPadding: {
        paddingBottom: 0,
    },
}));

export default function CustomizedInputBase({ lang }) {
    const classes = useStyles();
    const [email, setEmail] = React.useState("");
    const [fName, setFName] = React.useState("");
    const [lName, setLName] = React.useState("");
    return (
        <Card className={classes.root}>
            <div className={classes.item}>
                <CardHeader
                    classes={{ root: classes.headerPadding }}
                    title={
                        lang === "de"
                            ? "Newsletter abonnieren"
                            : "Signup for Newsletter"
                    }
                />
            </div>
            <CardContent>
                <div className={classes.container}>
                    <form
                        action="https://elielrom.us2.list-manage.com/subscribe/post"
                        method="POST"
                        noValidate
                    >
                        <input
                            type="hidden"
                            name="u"
                            value="01252a2b9937c336b5a94041b"
                        />
                        <input type="hidden" name="id" value="5fc66c3ce8" />
                        <div className={classes.item}>
                            <TextField
                                variant="outlined"
                                label={lang === "de" ? "Vorname" : "First Name"}
                                margin="none"
                                type="text"
                                name="FNAME"
                                color="secondary"
                                fullWidth
                                value={fName}
                                style={{
                                    marginRight: "1rem",
                                    marginTop: "1rem",
                                }}
                                onChange={(e) => {
                                    setFName(e.target.value);
                                }}
                            />
                            <TextField
                                variant="outlined"
                                label={lang === "de" ? "Nachname" : "Last Name"}
                                margin="none"
                                type="text"
                                name="LNAME"
                                color="secondary"
                                fullWidth
                                value={lName}
                                style={{ marginTop: "1rem" }}
                                onChange={(e) => {
                                    setLName(e.target.value);
                                }}
                            />
                        </div>
                        <div className={classes.item}>
                            <TextField
                                variant="outlined"
                                label="E-Mail"
                                margin="normal"
                                name="EMAIL"
                                color="secondary"
                                fullWidth
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                autoCapitalize="off"
                                autoCorrect="off"
                            />
                        </div>
                        <div className={classes.item}>
                            <p>
                                <Button
                                    className={classes.item}
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    value="subscribe"
                                    name="subscribe"
                                    size="large"
                                >
                                    {lang === "de" ? "Abonnieren" : "Subscribe"}
                                </Button>
                            </p>
                        </div>
                    </form>
                </div>
            </CardContent>
        </Card>
    );
}
