import * as React from "react";
import ReactPlayer from "react-player/soundcloud";
import { useCookies } from "react-cookie";

const SoundCloud = ({ url, playing }) => {
    const [cookies] = useCookies(["consent"]);
    return (
        <div
            style={{
                width: "100%",
                maxWidth: "640px",
                marginTop: "1em",
                marginBottom: "1em",
            }}
        >
            {typeof cookies.consent !== "undefined" ? (
                <ReactPlayer
                    height={0}
                    width="auto"
                    url={url}
                    playing={playing}
                />
            ) : null}
        </div>
    );
};
export default SoundCloud;
