import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
// import Select from '@material-ui/core/Select';
// import InputBase from '@material-ui/core/InputBase';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import TranslateIcon from '@material-ui/icons/Translate';
import { Button, ButtonGroup } from "@material-ui/core";

// const BootstrapInput = withStyles((theme) => ({
//     root: {
//         'label + &': {
//             marginTop: theme.spacing(3),
//         },
//     },
//     input: {
//         //   borderRadius: 4,
//         position: 'relative',
//         background: 'none',
//         //   border: '1px solid #ced4da',
//         fontSize: 16,
//         padding: '10px 26px 10px 12px',
//         transition: theme.transitions.create(['border-color', 'box-shadow']),
//         // Use the system font instead of the default Roboto font.
//         //   fontFamily: [
//         //     '-apple-system',
//         //     'BlinkMacSystemFont',
//         //     '"Segoe UI"',
//         //     'Roboto',
//         //     '"Helvetica Neue"',
//         //     'Arial',
//         //     'sans-serif',
//         //     '"Apple Color Emoji"',
//         //     '"Segoe UI Emoji"',
//         //     '"Segoe UI Symbol"',
//         //   ].join(','),
//         textTransform: 'uppercase',
//         // '&:focus': {
//         //     borderRadius: 4,
//         //     borderColor: '#80bdff',
//         //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//         // },
//     },
//   }))(InputBase);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        display: "inline",
        whiteSpace: "no-wrap",
        overflow: "hidden",
    },
    icon: {
        verticalAlign: "text-bottom",
    },
}));

export default function SimpleSelect({ lang, setLang, small }) {
    const classes = useStyles();
    const { pathname } = useLocation();
    var regex = new RegExp(lang + "(.+)", "g");
    const [, route] = pathname.split(regex);
    const history = useHistory();
    // const handleChange = (event) => {
    //     setLang(event.target.value);
    //     history.push(`/${event.target.value}${route}`);
    // };
    const handleChange = (language) => {
        setLang(language);
        // console.log(route);
        history.replace(`/${language}${route}`);
        // history.push(`/${language}${route}`);
    };
    return (
        <div>
            <FormControl className={classes.formControl}>
                {/* <TranslateIcon fontSize='small' htmlColor='#202020' className={classes.icon}/> */}
                {/* <Select
                    labelId="select"
                    id="select"
                    value={lang}
                    onChange={handleChange}
                    label="Language"
                    input={<BootstrapInput />}
                >
                    <MenuItem value={'de'}>Deutsch</MenuItem>
                    <MenuItem value={'en'}>English</MenuItem>
                </Select> */}
                {small ? (
                    <ButtonGroup variant="text" aria-label="language">
                        <Button
                            style={{ padding: "0" }}
                            disabled={lang === "de"}
                            onClick={() => handleChange("de")}
                        >
                            DE
                        </Button>
                        <Button
                            style={{ padding: "0" }}
                            disabled={lang === "en"}
                            onClick={() => handleChange("en")}
                        >
                            EN
                        </Button>
                    </ButtonGroup>
                ) : (
                    <ButtonGroup variant="text" aria-label="language">
                        <Button
                            disabled={lang === "de"}
                            onClick={() => handleChange("de")}
                        >
                            DE
                        </Button>
                        <Button
                            disabled={lang === "en"}
                            onClick={() => handleChange("en")}
                        >
                            EN
                        </Button>
                    </ButtonGroup>
                )}
            </FormControl>
        </div>
    );
}
