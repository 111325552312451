import React, { useState, useEffect } from "react";
import getAPIData from "../../api/getAPIDataNoSetState";
import Tour from "./Tour";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/Tour-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/Tour-Kopfbild-s.jpg'

export default function TourRoot({ lang, setCls }) {
    const [concertstate, setConcertState] = useState({
        concerts: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        getAPIData(
            "konzert",
            lang,
            (a, b) => new Date(a.fields.date) - new Date(b.fields.date),
            (response) => setConcertState({ concerts: response })
        );
    }, [lang]);

    // useEffect(() => {
    //     setCls('0vh');
    // }, [] )

    return (
        <>
            <EvaluateContent
                source={"Tour-Kopfbild.jpg"}
                bodycomp={
                    <Tour
                        concerts={concertstate.concerts.data}
                        isFetching={concertstate.concerts.isFetching}
                        lang={lang}
                    />
                }
            />
        </>
    );
}
