import * as React from "react";
import ReactPlayer from "react-player/youtube";
import {
    Grid,
    Backdrop,
    Card,
    CardContent,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import { Image } from "../organisation";
// import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    // video: {
    //     position: 'relative',
    //     paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
    // },
    // player: {
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    // },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    card: {
        width: "80vw",
        height: "40vw",
    },
    video: {
        cursor: "pointer",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    paddingText: {
        //Overrides CardContent Styles
        padding: theme.spacing(1),
        "&:last-child": { paddingBottom: 0 },
    },
}));

const YoutubeTile = ({ video, cookieAlertOpen, setCookieAlertOpen, themeMode }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [play, setPlay] = React.useState(false);
    const [cookies] = useCookies(["consent"]);
    const handleClose = () => {
        setPlay(false); //removed
        setOpen(false);
    };
    const handleToggle = () => {
        if (cookies.consent === "2" || cookies.consent === "4") {
            setCookieAlertOpen(true);
        } else {
            setOpen(!open);
            setPlay(true); //added
        }
    };
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={video.fields.url}>
            <div onClick={handleToggle} className={classes.video}>
                {/* in darkmode #121212 */}
                <Card style={{ background: themeMode === 'dark' ? '#121212' : "#fafafa" }} variant="elevation">
                    <Card style={{ background: themeMode === 'dark' ? '#121212' : "#fafafa" }} variant="elevation">
                        <Image
                            url={video.fields.thumbnail.fields.file.url}
                            alt={video.fields.title}
                            wM={550}
                            wT={550}
                            wD={550}
                        />
                        {/* <CardMedia
                            component="img"
                            className={classes.media}
                            image={'https://' + video.fields.thumbnail.fields.file.url+"?w=550&fm=webp"}
                            title={video.fields.title}
                        /> */}
                    </Card>
                    <CardContent classes={{ root: classes.paddingText }}>
                        <Typography
                            gutterBottom
                            variant="subtitle1"
                            component="h4"
                        >
                            {video.fields.title}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary" component="p">
                            {format(new Date(video.fields.date),"dd.MM.yyyy")}
                        </Typography> */}
                    </CardContent>
                </Card>
            </div>
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
            >
                <Card
                    style={{ background: "#000000" }}
                    variant="elevation"
                    className={classes.card}
                >
                    <ReactPlayer
                        url={video.fields.url}
                        width="100%"
                        height="100%"
                        playing={play}
                        onPlay={() => setPlay(true)}
                        onPause={() => setPlay(false)}
                        onEnded={handleClose}
                        config={{
                            youtube: {
                                playerVars: {
                                    controls: 1,
                                    origin: "http://localhost:3000",
                                },
                            },
                        }}
                    />
                </Card>
            </Backdrop>
        </Grid>
    );
};
export default YoutubeTile;
