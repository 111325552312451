import React, { useState, useEffect } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    CardActions,
    Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { getAPIDataNoSetState, downloadPressZip } from "../../api";
import DescriptionIcon from "@material-ui/icons/Description";
import { Image } from "../organisation";

const useStyles = makeStyles((theme) => ({
    press: {
        marginTop: theme.spacing(2),
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    pictures: {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    icon: {
        verticalAlign: "middle",
        marginRight: theme.spacing(0.5),
    },
    card: {
        background: "none",
    },
    item: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        margin: "auto",
    },
    link: {
        color: theme.palette.text.primary,
    },
    documents: {
        marginTop: theme.spacing(2),
    },
    filename: {
        fontSize: "0.9rem",
    },
}));

const PressKit = ({ lang, short }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        press: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        getAPIDataNoSetState(
            "pressKit",
            lang,
            (a, b) => 0,
            (response) => setState({ press: response })
        );
    }, [lang]);
    return (
        <Grid item xs={12} sm={12} md={12}>
            {state.press.isFetching ? (
                <div className={classes.quotes}>
                    <Skeleton variant="rect" animation="wave" height={330} />
                </div>
            ) : (
                <>
                    {state.press.data[0] && (
                        <Card
                            raised
                            variant="elevation"
                            className={classes.card}
                        >
                            <CardContent>
                                <div className={classes.item}>
                                    <Typography variant="h5" component="h3">
                                        Press Kit
                                    </Typography>
                                </div>
                                {short ? (
                                    <>
                                        <Grid
                                            container
                                            spacing={1}
                                            className={classes.press}
                                        >
                                            {state.press.data[0].fields.pictures
                                                .slice(0, 2)
                                                .map((picture) => (
                                                    <Grid
                                                        item
                                                        key={
                                                            picture.fields.file
                                                                .fileName
                                                        }
                                                        xs={6}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <Card
                                                            raised
                                                            variant="elevation"
                                                        >
                                                            <Image
                                                                url={
                                                                    picture
                                                                        .fields
                                                                        .file
                                                                        .url
                                                                }
                                                                alt={
                                                                    "PresseBild: " +
                                                                    picture
                                                                        .fields
                                                                        .file
                                                                        .fileName
                                                                }
                                                                lazy
                                                                wM={400}
                                                                wT={550}
                                                                wD={550}
                                                            />
                                                            {/* <CardMedia
                                                            component="img"
                                                            image={'https://' + picture.fields.file.url + "?w=550&q=70&fm=webp"}
                                                            /> */}
                                                        </Card>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid
                                            container
                                            spacing={1}
                                            className={classes.press}
                                        >
                                            {state.press.data[0].fields.pictures.map(
                                                (picture) => (
                                                    <Grid
                                                        item
                                                        key={
                                                            picture.fields.file
                                                                .fileName
                                                        }
                                                        xs={6}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <Card
                                                            raised
                                                            variant="elevation"
                                                        >
                                                            <Image
                                                                url={
                                                                    picture
                                                                        .fields
                                                                        .file
                                                                        .url
                                                                }
                                                                alt={
                                                                    "PresseBild: " +
                                                                    picture
                                                                        .fields
                                                                        .file
                                                                        .fileName
                                                                }
                                                                wM={400}
                                                                wT={550}
                                                                wD={550}
                                                            />
                                                            {/* <CardMedia
                                                            component="img"
                                                            image={'https://' + picture.fields.file.url + "?w=550&q=70&fm=webp"}
                                                            /> */}
                                                        </Card>
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                        <div className={classes.documents}>
                                            {state.press.data[0].fields.documents.map(
                                                (document) => (
                                                    <Typography
                                                        key={
                                                            document.fields.file
                                                                .url
                                                        }
                                                        className={
                                                            classes.filename
                                                        }
                                                    >
                                                        <DescriptionIcon
                                                            fontSize="small"
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                        <Link
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={
                                                                document.fields
                                                                    .file.url
                                                            }
                                                            className={
                                                                classes.link
                                                            }
                                                        >
                                                            {
                                                                document.fields
                                                                    .title
                                                            }
                                                        </Link>
                                                    </Typography>
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            </CardContent>
                            <CardActions>
                                <div
                                    style={{
                                        width: "100%",
                                        position: "relative",
                                        height: "40px",
                                    }}
                                >
                                    <Button
                                        style={{
                                            position: "absolute",
                                            right: "0px",
                                        }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() =>
                                            downloadPressZip(
                                                state.press.data[0]
                                            )
                                        }
                                    >
                                        {lang === "de"
                                            ? "ZIP herunterladen"
                                            : "Download as ZIP"}
                                    </Button>
                                </div>
                            </CardActions>
                        </Card>
                    )}
                </>
            )}
        </Grid>
    );
};
export default PressKit;
