import * as React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Youtube } from ".";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const manageGrid = (videos, lang, callCookies) => {
    const rows = [];
    for (var i in videos) {
        if (i === "0") {
            rows.push(
                <Hidden smDown key={0}>
                    <Grid item xs={2}>
                        
                    </Grid>
                </Hidden>
            )
            rows.push(
                <Grid item xs={12} md={8} key={videos[i]}>
                    <Youtube
                        url={videos[i]}
                        lang={lang}
                        callCookies={(bool) => callCookies(bool)}
                    />
                </Grid>
            );
            rows.push(
                <Hidden smDown key={1}>
                    <Grid item xs={2}>
                        
                    </Grid>
                </Hidden>
            )
        } else {
            rows.push(
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={videos[i]}>
                    <Youtube
                        url={videos[i]}
                        lang={lang}
                        callCookies={(bool) => callCookies(bool)}
                    />
                </Grid>
            );
        }
    }
    return rows;
};

const YoutubeTile = ({ videos, lang, callCookies }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {manageGrid(videos, lang, callCookies)}
            </Grid>
        </div>
    );
};
export default YoutubeTile;
