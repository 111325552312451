import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    img: {
        width: "100%",
        height: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
}));

const HeaderImage = ({ source, alt, contentful, sourceS }) => {
    const classes = useStyles();

    return (
        <>
            {contentful ? (
                <img
                    className={classes.img}
                    srcSet={
                        "https://" +
                        source +
                        "?w=800&q=100 800w, https://" +
                        source +
                        " 1600w"
                    }
                    src={"https://" + source + "?w=800&q=100"}
                    alt={alt}
                    width="100%"
                    height="100%"
                />
            ) : (
                <img
                    className={classes.img}
                    srcSet={
                        require("../header/pictures/Kopfbild-xs/" + source)
                            .default +
                        " 700w, " +
                        require("../header/pictures/Kopfbild-s/" + source)
                            .default +
                        " 850w, " +
                        require("../header/pictures/Kopfbild-m/" + source)
                            .default +
                        " 1300w, " +
                        require("../header/pictures/Kopfbild/" + source)
                            .default +
                        " 1800w"
                    }
                    src={
                        require("../header/pictures/Kopfbild-s/" + source)
                            .default
                    }
                    // srcSet={sourceS + " 800w, " + source + " 1600w"}
                    // src={sourceS}
                    alt={alt}
                    width="100%"
                    height="100%"
                />
            )}
        </>

        // <picture>
        //     {/* for desktop >900px */}
        //     {contentful
        //         ? <>
        //             <source
        //                 media="(min-width: 600px)"
        //                 srcSet={'https://' + source + '?fm=webp'}
        //                 type="image/webp"
        //             />
        //             <source
        //                 media="(min-width: 600px)"
        //                 srcSet={'https://' + source}
        //                 type="image/jpeg"
        //             />
        //         </>
        //         : <>
        //             {/* <source media="(min-width: 960px)" srcSet={sourceWebpB} type="image/webp"/> */}
        //             <source
        //                 media="(min-width: 600px)"
        //                 srcSet={source}
        //                 type="image/jpeg"
        //             />
        //         </>}
        //     {contentful
        //         ? <>
        //             <source
        //                 srcSet={'https://' + source + '?w=800&q=100&fm=webp'}
        //                 type="image/webp"
        //             />
        //             <source
        //                 srcSet={'https://' + source + '?w=800&q=100'}
        //                 type="image/jpeg"
        //             />
        //         </>
        //         : <>
        //             {/* <source srcSet={sourceWebpS} type="image/webp"/> */}
        //             <source
        //                 srcSet={sourceS ? sourceS : source}
        //                 type="image/jpeg"
        //             />
        //         </>}
        //     {/* legacy browser support */}
        //     {contentful
        //         ?   <img
        //                 className={classes.img}
        //                 src={'https://' + source + '?w=800&q=100'}
        //                 alt={alt}
        //                 width='100%'
        //                 height='100%'/>
        //         :   <img
        //                 className={classes.img}
        //                 src={source}
        //                 alt={alt}
        //                 width='100%'
        //                 height='100%'/>
        //         }

        // </picture>
    );
};
export default HeaderImage;
