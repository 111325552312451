import React, { useState, useEffect } from "react";
import Media from "./Media";
import getAPIData from "../../api/getAPIDataNoSetState";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/Medien-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/Medien-Kopfbild-s.jpg'

export default function MediaRoot({ lang, callCookies, themeMode }) {
    const [state, setState] = useState({
        media: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        getAPIData(
            "mediaYoutube",
            "de",
            (a, b) => new Date(b.fields.date) - new Date(a.fields.date),
            (response) => setState({ media: response })
        );
    }, []);
    return (
        <>
            <EvaluateContent
                source={"Medien-Kopfbild.jpg"}
                bodycomp={
                    <Media
                        media={state.media.data}
                        isFetching={state.media.isFetching}
                        callCookies={(bool) => callCookies(bool)}
                        lang={lang}
                        themeMode={themeMode}
                    />
                }
            />
        </>
    );
}
