import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from "@material-ui/core";
import ProjectTile from "./ProjectTile.js";
import PageStyle from "../organisation/PageStyle";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

const Projects = ({ content, isFetching, lang, callCookies }) => {
    const classes = useStyles();

    return (
        <PageStyle>
            <>
                {isFetching ? (
                    <Grid container spacing={3} className={classes.root}>
                        {[...new Array(2)].map((i, index) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{ height: "auto" }}
                            >
                                <Paper style={{ height: "100%" }}>
                                    <div style={{ padding: "16px" }}>
                                        <Typography variant="h5" component="h2">
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                            />
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                            />
                                        </Typography>
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            height={350}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <>
                        {content ? (
                            <>
                                <Grid
                                    container
                                    spacing={3}
                                    className={classes.root}
                                >
                                    {content.map((project) => (
                                        <Grid
                                            key={project.fields.title}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            style={{ height: "auto" }}
                                        >
                                            <ProjectTile
                                                data={project}
                                                lang={lang}
                                                callCookies={(bool) =>
                                                    callCookies(bool)
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        ) : null}
                    </>
                )}
            </>
        </PageStyle>
    );
};
export default Projects;
