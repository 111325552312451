import * as React from "react";
import PageStyle from "../organisation/PageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import YoutubeTile from "./YoutubeTile";
import Skeleton from "@material-ui/lab/Skeleton";
import CookieAlert from "../organisation/CookieAlert";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
}));

const Media = ({ media, isFetching, callCookies, lang, themeMode }) => {
    const classes = useStyles();
    const [cookieAlertOpen, setCookieAlertOpen] = React.useState(false);
    return (
        <PageStyle>
            <div className={classes.root}>
                {isFetching ? (
                    <>
                        <Grid container spacing={3}>
                            {[...new Array(6)].map((i, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                >
                                    <Skeleton
                                        variant="rect"
                                        animation="wave"
                                        height={"20vh"}
                                    />
                                    <Skeleton variant="text" animation="wave" />
                                    {/* <Skeleton variant="text" animation="wave" width={100}/> */}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ) : (
                    <>
                        {media ? (
                            <Grid container spacing={3}>
                                {media.map((video) => (
                                    <YoutubeTile
                                        key={video.fields.url}
                                        video={video}
                                        cookieAlertOpen={cookieAlertOpen}
                                        setCookieAlertOpen={(bool) =>
                                            setCookieAlertOpen(bool)
                                        }
                                        themeMode={themeMode}
                                    />
                                ))}
                            </Grid>
                        ) : null}
                    </>
                )}
            </div>
            <CookieAlert
                cookieAlertOpen={cookieAlertOpen}
                setCookieAlertOpen={(bool) => setCookieAlertOpen(bool)}
                callCookies={(bool) => callCookies(bool)}
                lang={lang}
            />
            {/* <iframe style={{borderRadius:'4px'}} title="spotify" src="https://open.spotify.com/embed/artist/5yHkQmxSec89BcCpLxRaae" width="100%" height="390" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe> */}
        </PageStyle>
    );
};
export default Media;
