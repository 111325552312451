import React, { useState, useEffect } from "react";
import Album from "./Album";
import CoverFlow from "./CoverFlow";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import getAPIData from "../../api/getAPIDataNoSetState";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/Music-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/Music-Kopfbild-s.jpg'

export default function MusicRoot(props) {
    const [state, setState] = useState({
        albums: { data: [], isFetching: true },
        searchString: "",
    });
    const lang = props.lang;
    let match = useRouteMatch();

    useEffect(() => {
        getAPIData(
            "album",
            lang,
            (a, b) =>
                new Date(b.fields.publicationdate) -
                new Date(a.fields.publicationdate),
            (response) => setState({ albums: response })
        );
    }, [lang]);
    return (
        <>
            <Switch>
                {/* {state.albums.data.map((album) => (
                    <Route exact key={album.fields.path} path={`/${lang}/music/album/${album.fields.path}`} render={(props) => (evaluateContent(album.fields.headerPicture ? album.fields.headerPicture.fields.file.url : header,<Album {...props} album={album} />))} />
                ))}
                <Route exact path={`/${lang}/music`} render={(props) => (evaluateContent(header,<CoverFlow {...props} content={state.albums.data} isFetching={state.albums.isFetching} lang={lang}/>))} /> */}
                {/* {state.albums.data.map((album) => (
                    <Route key={album.fields.path} path={`${match.path}/album/${album.fields.path}`}>
                         {evaluateContent(album.fields.headerPicture ? album.fields.headerPicture.fields.file.url : header,<Album album={album} />)}
                    </Route>
                    // <Route key={album.fields.path} path={`${match.path}/album/${album.fields.path}`} render={(props) => (evaluateContent(album.fields.headerPicture ? album.fields.headerPicture.fields.file.url : header,<Album {...props} album={album} />))} />
                    //     {evaluateContent(album.fields.headerPicture ? album.fields.headerPicture.fields.file.url : header,<Album {...props} album={album} />)}
                    // </Route>
                ))} */}
                {/* CHANGED routing match.path to match.url!!! */}
                <Redirect
                    from="/de/music/live"
                    to={`/de/music/johannes-mössinger-quartett-live`}
                />
                <Redirect
                    from="/en/music/live"
                    to={`/en/music/johannes-mössinger-quartett-live`}
                />
                <Redirect
                    from="/de/music/live-at-studio-bauer-2/"
                    to={`/de/music/live-at-studio-bauer/`}
                />
                <Redirect
                    from="/en/music/live-at-studio-bauer-2/"
                    to={`/en/music/live-at-studio-bauer/`}
                />
                <Redirect
                    from="/de/music/j-s-bach-inventionen/"
                    to={`/de/music/j-s-bach-inventionen-und-sinfonien/`}
                />
                <Redirect
                    from="/en/music//j-s-bach-inventions-and-sinfonias/"
                    to={`/en/music/j-s-bach-inventionen-und-sinfonien/`}
                />
                <Redirect
                    from="/de/music/poetry-2012/"
                    to={`/de/music/poetry`}
                />
                <Redirect
                    from="/en/music/poetry-2012/"
                    to={`/en/music/poetry`}
                />

                <Route
                    path={`${match.url}/:album`}
                    children={
                        <Album
                            albums={state.albums.data}
                            isFetching={state.albums.isFetching}
                            lang={lang}
                            callCookies={(bool) => props.callCookies(bool)}
                        />
                    }
                />
                <Route exact path={match.url}>
                    <EvaluateContent
                        source={"Music-Kopfbild.jpg"}
                        bodycomp={
                            <CoverFlow
                                {...props}
                                content={state.albums.data}
                                isFetching={state.albums.isFetching}
                                lang={lang}
                            />
                        }
                    />
                </Route>
            </Switch>
        </>
    );
}
