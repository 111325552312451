import React, { lazy, Suspense, useState } from 'react';
import * as Page from '.';
import { Container } from '@material-ui/core';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PageTitle } from './organisation';

function PageContent({ lang, cookiedisk, callCookies, mounted, themeMode }) {
    const Home = lazy(() => import('./home/HomeRoot'));
    const About = lazy(() => import('./about/AboutRoot'));
    const Media = lazy(() => import('./media/MediaRoot'));
    const News = lazy(() => import('./news/NewsRoot'));
    const Tour = lazy(() => import('./kalender/TourRoot'));
    const Impressum = lazy(() => import('./impressum/ImpressumRoot'));
    const DSGVO = lazy(() => import('./dsgvo/DSGVORoot'));
    const Form = lazy(() => import('./form/FormRoot'));

    const [cls, setCls] = useState('100vh');

    return (
        <Container style={{ padding: '0', minHeight: cls }} maxWidth={false}>
            {' '}
            {/* Added minHeight to reduce CLS drastically*/}
            <Suspense fallback={<Page.Organisation.FallBack />}>
                <Switch>
                    {/* CHANGED ALL ${lang} to :lang when updating the routing */}
                    <Route exact path={`/:lang/home`}>
                        <PageTitle
                            title='Johannes Mössinger | Jazzpianist, Komponist'
                            description={
                                lang === 'de'
                                    ? 'Johannes Mössinger arbeitete mit Joe Lovano, Joel Frahm, Adam Nussbaum, Seamus Blake, u.v.m. zusammen. Er veröffentlichte 25 Alben als Leader produziert in Europa und den USA. Erfolgreiche CDs: Monk’s Corner, New By Two, Nu Love.'
                                    : 'Johannes Mössinger worked with Joe Lovano, Joel Frahm, Adam Nussbaum, Seamus Blake, and many more. He has released 25 albums as a leader produced in Europe and the USA. Successful CDs: Monk’s Corner, New By Two, Nu Love.'
                            }
                            pathSlug={lang + '/home'}
                            keywords={
                                lang === 'de'
                                    ? ['Jazzpianist', 'Musiker', 'Komponist']
                                    : ['jazz pianist', 'musician', 'composer']
                            }
                        >
                            <Home
                                lang={lang}
                                setCls={(str) => setCls(str)}
                                callCookies={(bool) => callCookies(bool)}
                            />
                        </PageTitle>
                    </Route>
                    <Route exact path={`/:lang/about`}>
                        <PageTitle
                            title={
                                (lang === 'de' ? 'Über' : 'About') +
                                ' | Johannes Mössinger'
                            }
                            description={
                                lang === 'de'
                                    ? 'Johannes Mössinger zählt zu den kreativsten Jazzmusikern in Deutschland und feierte zuletzt große Erfolge mit seinem Album NEW BY TWO zusammen mit dem NYer Saxophonisten Joel Frahm.'
                                    : 'Johannes Mössinger is one of the most creative jazz musicians in Germany and recently celebrated great success with his album NEW BY TWO together with the NY saxophonist Joel Frahm.'
                            }
                            pathSlug={'de/about'}
                            keywords={
                                lang === 'de'
                                    ? [
                                          'Jazzpianist',
                                          'Musiker',
                                          'Komponist',
                                          'About',
                                          'Über',
                                          'Leben',
                                          'Biographie',
                                      ]
                                    : [
                                          'jazz pianist',
                                          'musician',
                                          'composer',
                                          'life',
                                          'about',
                                          'biography',
                                      ]
                            }
                        >
                            <About lang={lang} setCls={(str) => setCls(str)} />
                        </PageTitle>
                    </Route>
                    <Route
                        path={`/:lang/music`}
                        render={(props) => (
                            <PageTitle
                                title={
                                    (lang === 'de' ? 'Musik' : 'Music') +
                                    ' | Johannes Mössinger'
                                }
                                description={
                                    lang === 'de'
                                        ? 'CD Solo Juli 2018 - Johannes Mössinger Solo | CD Bach Inventionen | CD New By Two mit Joel Frahm | LP/CD Studio Konzert Mössinger US Quartett'
                                        : 'CD Solo July 2018 - Johannes Mössinger Solo | CD Bach Inventions | CD New By Two with Joel Frahm | LP / CD studio concert Mössinger US Quartet'
                                }
                                pathSlug={'de/music'}
                                keywords={
                                    lang === 'de'
                                        ? [
                                              'Jazzpianist',
                                              'Musiker',
                                              'Komponist',
                                              'Musik',
                                              'Alben',
                                              'Werk',
                                              'Kompositionen',
                                          ]
                                        : [
                                              'jazz pianist',
                                              'musician',
                                              'composer',
                                              'Music',
                                              'Albums',
                                              'Compositions',
                                          ]
                                }
                            >
                                <Page.MusicRoot
                                    {...props}
                                    lang={lang}
                                    setCls={(str) => setCls(str)}
                                    callCookies={(bool) => callCookies(bool)}
                                />
                            </PageTitle>
                        )}
                    />
                    <Route exact path={`/:lang/media`}>
                        <PageTitle
                            title={
                                (lang === 'de' ? 'Videos' : 'Media') +
                                ' | Johannes Mössinger'
                            }
                            description={
                                lang === 'de'
                                    ? 'Live Videos von Konzerten. Johannes Mössinger mit Adam Nussbaum, Andy Hunter, Karl Latham, Jochen Schorer, Joel Frahm u.v.m.'
                                    : 'Live videos from concerts. Johannes Mössinger with Adam Nussbaum, Andy Hunter, Karl Latham, Jochen Schorer, Joel Frahm and many more.'
                            }
                            pathSlug={'de/media'}
                            keywords={
                                lang === 'de'
                                    ? [
                                          'Jazzpianist',
                                          'Musiker',
                                          'Komponist',
                                          'Videos',
                                          'Medien',
                                          'Konzerte',
                                      ]
                                    : [
                                          'jazz pianist',
                                          'musician',
                                          'composer',
                                          'videos',
                                          'concerts',
                                          'media',
                                      ]
                            }
                        >
                            <Media
                                lang={lang}
                                setCls={(str) => setCls(str)}
                                callCookies={(bool) => callCookies(bool)}
                                themeMode={themeMode}
                            />
                        </PageTitle>
                    </Route>
                    <Route exact path={`/:lang/news`}>
                        <PageTitle
                            title={
                                (lang === 'de' ? 'News' : 'News') +
                                ' | Johannes Mössinger'
                            }
                            description={
                                lang === 'de'
                                    ? 'Aktuelle Neuigkeiten aus dem Leben des Künstlers Johannes Mössinger. Konzerte, Tourplanungen, Fotos, neue Videos, Infos aller Art'
                                    : 'Current news from the life of the artist Johannes Mössinger. Concerts, tour plans, photos, new videos, all kinds of information'
                            }
                            pathSlug={'de/news'}
                            keywords={
                                lang === 'de'
                                    ? [
                                          'Jazzpianist',
                                          'Musiker',
                                          'Komponist',
                                          'News',
                                          'Nachrichten',
                                          'Neuigkeiten',
                                      ]
                                    : [
                                          'jazz pianist',
                                          'musician',
                                          'composer',
                                          'News',
                                          'Blog',
                                          'Information',
                                      ]
                            }
                        >
                            <News
                                lang={lang}
                                setCls={(str) => setCls(str)}
                                callCookies={(bool) => callCookies(bool)}
                            />
                        </PageTitle>
                    </Route>
                    <Route
                        path={`/:lang/projects`}
                        render={(props) => (
                            <PageTitle
                                title={
                                    (lang === 'de' ? 'Projekte' : 'Projects') +
                                    ' | Johannes Mössinger'
                                }
                                description={
                                    lang === 'de'
                                        ? 'Neu: Jazz Classics Musik von George Gershwin Johannes Mössinger mit Stephan Zimmermann, Thomas Lähns, Michael Heidepriem. Das Johannes Mössinger US Quartett mit Adam Nussbaum, das Duo Johannes Mössinger/Joel Frahm u.v.m.'
                                        : 'New: Jazz Classics music by George Gershwin Johannes Mössinger with Stephan Zimmermann, Thomas Lähns, Michael Heidepriem. The Johannes Mössinger US Quartet with Adam Nussbaum, the duo Johannes Mössinger / Joel Frahm and many more.'
                                }
                                pathSlug={'de/projects'}
                                keywords={
                                    lang === 'de'
                                        ? [
                                              'Jazzpianist',
                                              'Musiker',
                                              'Komponist',
                                              'Projekte',
                                              'Bands',
                                              'Konstellationen',
                                          ]
                                        : [
                                              'jazz pianist',
                                              'musician',
                                              'composer',
                                              'Projects',
                                              'Bands',
                                              'Constellations',
                                          ]
                                }
                            >
                                <Page.ProjectsRoot
                                    {...props}
                                    lang={lang}
                                    setCls={(str) => setCls(str)}
                                    callCookies={(bool) => callCookies(bool)}
                                />
                            </PageTitle>
                        )}
                    />
                    <Route exact path={`/:lang/tour`}>
                        <PageTitle
                            title={
                                (lang === 'de' ? 'Tour' : 'Tour') +
                                ' | Johannes Mössinger'
                            }
                            description={
                                lang === 'de'
                                    ? 'Johannes Mössinger tourte bereits in Deutschland, Europa, Russland und den USA. Er ist in verschiedenen Besetzungen mit Künstler*innen aus aller Welt unterwegs.'
                                    : 'Johannes Mössinger toured in Germany, Europe, Russia and the USA. He is on the road in various casts with artists from all over the world.'
                            }
                            pathSlug={'de/tour'}
                            keywords={
                                lang === 'de'
                                    ? [
                                          'Jazzpianist',
                                          'Musiker',
                                          'Komponist',
                                          'Tour',
                                          'Tournee',
                                          'Konzerte',
                                          'Termine',
                                          'Shows',
                                          'live',
                                      ]
                                    : [
                                          'jazz pianist',
                                          'musician',
                                          'composer',
                                          'Tour',
                                          'Concerts',
                                          'Live',
                                          'Shows',
                                          'Locations',
                                      ]
                            }
                        >
                            <Tour lang={lang} setCls={(str) => setCls(str)} />
                        </PageTitle>
                    </Route>
                    <Route exact path={`/:lang/impressum`}>
                        <PageTitle
                            title={
                                (lang === 'de' ? 'Impressum' : 'Legal Notice') +
                                ' | Johannes Mössinger'
                            }
                            description={false}
                            pathSlug={'de/impressum'}
                            keywords={['Jazzpianist', 'Musiker', 'Komponist']}
                        >
                            <Impressum
                                lang={lang}
                                setCls={(str) => setCls(str)}
                            />
                        </PageTitle>
                    </Route>
                    <Route exact path={`/:lang/gdpr`}>
                        <PageTitle
                            title={
                                (lang === 'de'
                                    ? 'Datenschutz'
                                    : 'Legal Disclosure') +
                                ' | Johannes Mössinger'
                            }
                            description={false}
                            pathSlug={'de/gdrp'}
                            keywords={['Jazzpianist', 'Musiker', 'Komponist']}
                        >
                            <DSGVO
                                lang={lang}
                                mounted={mounted}
                                cookiedisk={(x) => cookiedisk(x)}
                                setCls={(str) => setCls(str)}
                            />
                        </PageTitle>
                    </Route>
                    <Route exact path={`/:lang/form`}>
                        <PageTitle
                            title={
                                (lang === 'de'
                                    ? 'Contakt/Bestellung'
                                    : 'Contact/Purchase') +
                                ' | Johannes Mössinger'
                            }
                            description={false}
                            pathSlug={'de/form'}
                            keywords={['Jazzpianist', 'Musiker', 'Komponist']}
                        >
                            <Form lang={lang} setCls={(str) => setCls(str)} />
                        </PageTitle>
                    </Route>
                    <Redirect from='/news/de-news/' to={`/de/news`} />
                    <Redirect from='/news/en-news/' to={`/en/news`} />
                    <Redirect from='/de/ueber/' to={`/de/about`} />
                    <Redirect from='/news/projekte/' to={`/${lang}/projects`} />
                    <Redirect
                        from='/projekte/projekt-1/'
                        to={`/${lang}/projects/johannes-mössinger-new-quartett`}
                    />
                    <Redirect from='/' to={`/${lang}/home`} />
                </Switch>
            </Suspense>
        </Container>
    );
}
export default PageContent;
