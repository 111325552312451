import React from "react";
import {
    makeStyles,
    Button,
    Drawer,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Hidden,
    Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import LanguageSelect from "../header/LanguageSelect";
import ReactGA from "react-ga";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.background.default,
    },
    drawer: {
        borderRadius: "4px",
    },
    nopadding: {
        padding: "0",
    },
    root: {
        padding: theme.spacing(2),
        width: "100%",
        minHeight: "20vh",
        maxHeight: "60vh",
        maxWidth: "1600px",
        margin: "auto",
        paddingLeft: "4vw",
        paddingRight: "4vw",
    },
    link: {
        color: theme.palette.secondary.main,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    buttonsCenter: {
        textAlign: "center",
        // marginBottom: theme.spacing(2),
    },
    buttonsRight: {
        textAlign: "right",
        // marginBottom: theme.spacing(2),
    },
    buttonSurround: {
        margin: theme.spacing(1),
    },
    buttonmargin: {
        // marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttonbottom: {
        marginBottom: theme.spacing(2),
    },
    choose: {
        textAlign: "left",
    },
    alink: {
        "&:link": {
            textDecoration: "none",
            color: theme.palette.secondary.light,
        },
        "&:visited": {
            textDecoration: "none",
            color: theme.palette.secondary.dark,
        },
        "&:hover": {
            textDecoration: "none",
            color: theme.palette.secondary.dark,
        },
    },
    normalText: {},
    smallText: {
        fontSize: "0.8rem",
    },
    smallTitle: {
        fontSize: "1rem",
    },
}));

// function isOverflown(element) {
//     return element.scrollHeight > element.clientHeight
//   }

// const DownArrow = () => {
//     console.log(isOverflown(document.getElementById("drawer")))
//     return test;
// }

const ConsentDrawer = ({
    state,
    setState,
    lang,
    setLang,
    forceOpen,
    setForceOpen,
}) => {
    const classes = useStyles();
    //0 = fail / 1 = accepted all / 2 = only essential / 3 = ess & fun / 4 = ess & ana
    const [cookies, setCookie] = useCookies(["consent"]);
    //functional check
    const [checkA, setCheckA] = React.useState(
        cookies.consent === "1" || cookies.consent === "3"
    );
    //analysis check
    const [checkB, setCheckB] = React.useState(
        cookies.consent === "1" || cookies.consent === "4"
    );
    //for Google Analytics
    const [consentGiven, setConsentGiven] = React.useState(
        cookies.consent === "1" || cookies.consent === "4"
    );
    function timecalc(days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        return date;
    }
    const handleClose = (boolA, boolB) => {
        //for functional cookies
        if (boolA && boolB) {
            setCookie("consent", "1", {
                path: "/",
                expires: timecalc(365),
                sameSite: "lax",
            });
            setCheckA(true);
            setCheckB(true);
        } else {
            if (!boolA && !boolB) {
                setCookie("consent", "2", {
                    path: "/",
                    expires: timecalc(365),
                    sameSite: "lax",
                });
            } else {
                if (boolA && !boolB) {
                    setCookie("consent", "3", {
                        path: "/",
                        expires: timecalc(365),
                        sameSite: "lax",
                    });
                } else {
                    setCookie("consent", "4", {
                        path: "/",
                        expires: timecalc(365),
                        sameSite: "lax",
                    });
                }
            }
        }

        setConsentGiven(boolB);
        setState(false);
        setForceOpen(false);
    };
    React.useEffect(() => {
        if (consentGiven === true) {
            ReactGA.initialize("UA-178393051-1");
            ReactGA.pageview(window.location.pathname);
        }
    }, [consentGiven, cookies.consent]);

    if (typeof cookies.consent == "undefined" || forceOpen) {
        return (
            <Drawer
                classes={{ paperAnchorBottom: classes.drawer }}
                anchor={"bottom"}
                open={state || forceOpen}
            >
                <div id={"drawer"} className={classes.root} role="presentation">
                    <Hidden smUp>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <Typography
                                    variant="h5"
                                    className={classes.smallTitle}
                                >
                                    {lang === "de"
                                        ? "Cookie Präferenzen verwalten"
                                        : "Manage cookie preferences"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.buttonsRight}>
                                <div>
                                    <LanguageSelect
                                        lang={lang}
                                        setLang={(x) => setLang(x)}
                                        small
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <div className={classes.choose}>
                                            <FormControlLabel
                                                style={{ fontSize: "0.8rem" }}
                                                disabled
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        checked
                                                        name="defaultCookies"
                                                    />
                                                }
                                                label={
                                                    lang === "de" ? (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            Unbedingt
                                                            erforderliche
                                                            Cookies
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            Absolutely necessary
                                                            cookies
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <div className={classes.choose}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkA}
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        onChange={() =>
                                                            setCheckA(!checkA)
                                                        }
                                                        name="functionalCookies"
                                                    />
                                                }
                                                label={
                                                    lang === "de" ? (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            Funktionale Cookies
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            functional Cookies
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <div className={classes.choose}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkB}
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        onChange={() =>
                                                            setCheckB(!checkB)
                                                        }
                                                        name="analisisCookies"
                                                    />
                                                }
                                                label={
                                                    lang === "de" ? (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            Analyse Cookies
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            analysis Cookies
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.buttonsCenter}
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleClose(checkA, checkB)}
                                >
                                    {lang === "de"
                                        ? "Meine Auswahl bestätigen"
                                        : "Confirm my choice"}
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.buttonsCenter}
                                style={{ marginBottom: "8px" }}
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleClose(true, true)}
                                >
                                    {lang === "de"
                                        ? "Alle zulassen und fortsetzen"
                                        : "Allow all and continue"}
                                </Button>
                            </Grid>
                            <Grid xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    className={classes.smallTitle}
                                >
                                    {lang === "de"
                                        ? "Ich respektiere Ihre Privatsphäre"
                                        : "I respect your privacy"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.smallText}>
                                    {lang === "de"
                                        ? "Ich (Johannes Mössinger) und bestimmte Dritte verwenden Cookies auf „johannesmoessinger.com“. Einzelheiten zu den Arten von Cookies, ihrem Zweck und den beteiligten Stellen finden Sie unten und in unserem Cookie Hinweis. Bitte willigen Sie in die Verwendung von Cookies ein, wie in unserem Cookie Hinweis beschrieben, indem Sie auf „Alle zulassen und fortsetzen“ klicken, um die bestmögliche Nutzererfahrung auf meiner Webseite zu haben. Sie können auch Ihre bevorzugten Einstellungen vornehmen oder Cookies ablehnen (mit Ausnahme unbedingt erforderlicher Cookies)."
                                        : 'I (Johannes Mössinger) and certain third parties use cookies on "johannesmoessinger.com". Details on the types of cookies, their purpose and the bodies involved can be found below and in our cookie notice. Please agree to the use of cookies, as described in our cookie notice, by clicking on "Allow all and continue" in order to have the best possible user experience on my website. You can also make your preferred settings or reject cookies (with the exception of absolutely necessary cookies).'}
                                    <Link
                                        target="_blank"
                                        to={`/${lang}/gdpr`}
                                        style={{ textDecoration: "none" }}
                                        className={classes.link}
                                    >
                                        {lang === "de"
                                            ? " Cookie Hinweis und weitere Informationen"
                                            : " Cookie notice and further information"}
                                    </Link>
                                </Typography>
                            </Grid>
                            {forceOpen && (
                                <Grid item xs={12}>
                                    <Typography
                                        style={{ fontStyle: "italic" }}
                                        className={classes.smallText}
                                    >
                                        {lang === "de"
                                            ? "Achtung: Wenn sie die funktionalen Cookies deaktivieren wollen müssen Sie die Cookies, die von Dritten gesetzt wurden manuell löschen. Danach werden keine neuen mehr durch Dritte gesetzt. "
                                            : "Attention: If you want to deactivate the functional cookies, you have to manually delete the cookies that were set by third parties. After that, no third partys will set new ones. "}
                                        <a
                                            className={classes.alink}
                                            target="_blank"
                                            rel="noopener noreferrer nofollow"
                                            href={
                                                "https://www.ionos.de/digitalguide/websites/webseiten-erstellen/cookies-loeschen/"
                                            }
                                        >
                                            {lang === "de" ? "Hier " : "Here "}
                                        </a>
                                        {lang === "de"
                                            ? "finden Sie eine Anleitung für die gängigsten Browser."
                                            : "you will find instructions for the most common browsers."}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>

                    <Hidden xsDown>
                        <Grid container spacing={1}>
                            <Grid item xs={8} sm={9}>
                                <Typography variant="h5">
                                    {lang === "de"
                                        ? "Ich respektiere Ihre Privatsphäre"
                                        : "I respect your privacy"}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={3}
                                className={classes.buttonsRight}
                            >
                                <div>
                                    <LanguageSelect
                                        lang={lang}
                                        setLang={(x) => setLang(x)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {lang === "de"
                                        ? "Ich (Johannes Mössinger) und bestimmte Dritte verwenden Cookies auf „johannesmoessinger.com“. Einzelheiten zu den Arten von Cookies, ihrem Zweck und den beteiligten Stellen finden Sie unten und in unserem Cookie Hinweis. Bitte willigen Sie in die Verwendung von Cookies ein, wie in unserem Cookie Hinweis beschrieben, indem Sie auf „Alle zulassen und fortsetzen“ klicken, um die bestmögliche Nutzererfahrung auf meiner Webseite zu haben. Sie können auch Ihre bevorzugten Einstellungen vornehmen oder Cookies ablehnen (mit Ausnahme unbedingt erforderlicher Cookies)."
                                        : 'I (Johannes Mössinger) and certain third parties use cookies on "johannesmoessinger.com". Details on the types of cookies, their purpose and the bodies involved can be found below and in our cookie notice. Please agree to the use of cookies, as described in our cookie notice, by clicking on "Allow all and continue" in order to have the best possible user experience on my website. You can also make your preferred settings or reject cookies (with the exception of absolutely necessary cookies).'}
                                    <Link
                                        target="_blank"
                                        to={`/${lang}/gdpr`}
                                        style={{ textDecoration: "none" }}
                                        className={classes.link}
                                    >
                                        {lang === "de"
                                            ? " Cookie Hinweis und weitere Informationen"
                                            : " Cookie notice and further information"}
                                    </Link>
                                </Typography>
                            </Grid>
                            {forceOpen && (
                                <Grid item xs={12}>
                                    <Typography style={{ fontStyle: "italic" }}>
                                        {lang === "de"
                                            ? "Achtung: Wenn sie die funktionalen Cookies deaktivieren wollen müssen Sie die Cookies, die von Dritten gesetzt wurden manuell löschen. Danach werden keine neuen mehr durch Dritte gesetzt. "
                                            : "Attention: If you want to deactivate the functional cookies, you have to manually delete the cookies that were set by third parties. After that, no third partys will set new ones. "}
                                        <a
                                            className={classes.alink}
                                            target="_blank"
                                            rel="noopener noreferrer nofollow"
                                            href={
                                                "https://www.ionos.de/digitalguide/websites/webseiten-erstellen/cookies-loeschen/"
                                            }
                                        >
                                            {lang === "de" ? "Hier " : "Here "}
                                        </a>
                                        {lang === "de"
                                            ? "finden Sie eine Anleitung für die gängigsten Browser."
                                            : "you will find instructions for the most common browsers."}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {lang === "de"
                                        ? "Cookie Präferenzen verwalten"
                                        : "Manage cookie preferences"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <div className={classes.choose}>
                                            <FormControlLabel
                                                disabled
                                                control={
                                                    <Checkbox
                                                        checked
                                                        name="defaultCookies"
                                                    />
                                                }
                                                label={
                                                    lang === "de"
                                                        ? "Unbedingt erforderliche Cookies"
                                                        : "Absolutely necessary cookies"
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <div className={classes.choose}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkA}
                                                        onChange={() =>
                                                            setCheckA(!checkA)
                                                        }
                                                        name="functionalCookies"
                                                    />
                                                }
                                                label={
                                                    lang === "de"
                                                        ? "Funktionale Cookies"
                                                        : "functional Cookies"
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <div className={classes.choose}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkB}
                                                        onChange={() =>
                                                            setCheckB(!checkB)
                                                        }
                                                        name="analisisCookies"
                                                    />
                                                }
                                                label={
                                                    lang === "de"
                                                        ? "Analyse Cookies"
                                                        : "analysis Cookies"
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.buttonsRight}>
                                <Button
                                    className={classes.buttonSurround}
                                    variant="outlined"
                                    onClick={() => handleClose(checkA, checkB)}
                                >
                                    {lang === "de"
                                        ? "Meine Auswahl bestätigen"
                                        : "Confirm my choice"}
                                </Button>
                                <Button
                                    className={classes.buttonSurround}
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    onClick={() => handleClose(true, true)}
                                >
                                    {lang === "de"
                                        ? "Alle zulassen und fortsetzen"
                                        : "Allow all and continue"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Hidden>
                </div>
            </Drawer>
        );
    } else {
        return null;
    }
};
export default ConsentDrawer;
