import * as React from "react";
import {
    PageStyle,
    EvaluateContent,
    Youtube,
    RichText,
    PageTitle,
    Image,
} from "../organisation";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    subtitle: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        marginBottom: theme.spacing(3),
    },
    title: {
        fontSize: "3rem",
        marginBottom: theme.spacing(4),
    },
    subcontent: {
        marginBottom: theme.spacing(3),
    },
}));

const Project = ({ project, isFetching, lang, callCookies }) => {
    const classes = useStyles();
    return (
        <PageStyle>
            {isFetching ? (
                <div className={classes.root}>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={classes.title}
                    >
                        <Skeleton variant="text" animation="wave" />
                    </Typography>
                    <Typography
                        variant="body2"
                        component="h3"
                        className={classes.subtitle}
                    >
                        <Skeleton variant="text" animation="wave" />
                    </Typography>
                    <div className={classes.subcontent}>
                        <Skeleton
                            variant="rect"
                            animation="wave"
                            height={"4rem"}
                        />
                    </div>
                    <div className={classes.subcontent}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <Skeleton
                                    variant="rect"
                                    animation="wave"
                                    height={"20rem"}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Skeleton
                                    variant="rect"
                                    animation="wave"
                                    height={"20rem"}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.subcontent}>
                        <Skeleton
                            variant="rect"
                            animation="wave"
                            height={"40rem"}
                        />
                    </div>
                </div>
            ) : (
                <>
                    {project ? (
                        <div className={classes.root}>
                            <Typography
                                variant="h2"
                                component="h2"
                                className={classes.title}
                            >
                                {project.fields.title}
                            </Typography>
                            {project.fields.musicians ? (
                                <Typography
                                    variant="body2"
                                    component="h3"
                                    className={classes.subtitle}
                                >
                                    {project.fields.musicians}
                                </Typography>
                            ) : null}
                            <div className={classes.subcontent}>
                                <RichText richText={project.fields.abstract} />
                            </div>
                            <div className={classes.subcontent}>
                                {(project.fields.youtubeVideos
                                    ? project.fields.youtubeVideos.length === 2
                                    : false) ||
                                (project.fields.pictures
                                    ? project.fields.pictures.length === 2
                                    : false) ? (
                                    <div>
                                        {project.fields.youtubeVideos ? (
                                            <>
                                                <Grid container spacing={3}>
                                                    {project.fields.youtubeVideos.map(
                                                        (video) => (
                                                            <Grid
                                                                item
                                                                key={video}
                                                                xs={12}
                                                                sd={12}
                                                                md={6}
                                                            >
                                                                <Card
                                                                    elevation={
                                                                        0
                                                                    }
                                                                    variant="elevation"
                                                                >
                                                                    <Youtube
                                                                        url={
                                                                            video
                                                                        }
                                                                        lang={
                                                                            lang
                                                                        }
                                                                        callCookies={(
                                                                            bool
                                                                        ) =>
                                                                            callCookies(
                                                                                bool
                                                                            )
                                                                        }
                                                                    />
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                {project.fields.pictures ? (
                                                    <>
                                                        <Grid
                                                            container
                                                            spacing={3}
                                                        >
                                                            {project.fields.pictures.map(
                                                                (picture) => (
                                                                    <Grid
                                                                        item
                                                                        key={
                                                                            picture
                                                                                .fields
                                                                                .file
                                                                                .url
                                                                        }
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                    >
                                                                        <Card
                                                                            elevation={
                                                                                0
                                                                            }
                                                                            variant="elevation"
                                                                        >
                                                                            <Image
                                                                                url={
                                                                                    picture
                                                                                        .fields
                                                                                        .file
                                                                                        .url
                                                                                }
                                                                                alt={
                                                                                    project
                                                                                        .fields
                                                                                        .title
                                                                                }
                                                                                wM={
                                                                                    600
                                                                                }
                                                                                wT={
                                                                                    600
                                                                                }
                                                                                wD={
                                                                                    800
                                                                                }
                                                                            />
                                                                            {/* <CardMedia
                                                                            className={classes.cover}
                                                                            component="img"
                                                                            image={'https://' + picture.fields.file.url + '?fm=webp'}
                                                                            title={project.fields.title}
                                                                        /> */}
                                                                        </Card>
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <Card elevation={0} variant="elevation">
                                        {project.fields.youtubeVideos ? (
                                            <>
                                                <Youtube
                                                    url={
                                                        project.fields
                                                            .youtubeVideos[0]
                                                    }
                                                    lang={lang}
                                                    callCookies={(bool) =>
                                                        callCookies(bool)
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {project.fields.pictures ? (
                                                    <Image
                                                        url={
                                                            project.fields
                                                                .picutres[0]
                                                                .fields.file.url
                                                        }
                                                        alt={
                                                            project.fields.title
                                                        }
                                                        wM={600}
                                                        wT={600}
                                                        wD={800}
                                                    />
                                                ) : // <CardMedia
                                                //     className={classes.cover}
                                                //     component="img"
                                                //     image={'https://' + project.fields.picutres[0].fields.file.url + '?fm=webp'}
                                                //     title={project.fields.title}
                                                // />
                                                null}
                                            </>
                                        )}
                                    </Card>
                                )}
                            </div>
                            <div className={classes.subcontent}>
                                <RichText
                                    richText={project.fields.description}
                                />
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </PageStyle>
    );
};

const ProjectRoot = ({ projects, isFetching, lang, callCookies }) => {
    const [myProject, setMyProject] = React.useState();
    const { project } = useParams();
    React.useEffect(() => {
        const getProject = () => {
            for (var i = 0; i < projects.length; i++) {
                if (projects[i].fields.path === project) {
                    return projects[i];
                }
            }
            console.log("error matching project path");
        };
        setMyProject(getProject());
    }, [projects, project]);
    return (
        <div>
            <EvaluateContent
                source={
                    myProject
                        ? myProject.fields.header
                            ? myProject.fields.header.fields.file.url
                            : null
                        : null
                }
                bodycomp={
                    <PageTitle
                        title={
                            myProject
                                ? myProject.fields.title +
                                  " | Johannes Mössinger"
                                : (lang === "de" ? "Projekte" : "Projects") +
                                  " | Johannes Mössinger"
                        }
                        description={
                            myProject ? myProject.fields.seoDescription : ""
                        }
                        keywords={
                            myProject ? myProject.fields.seoKeywords : [""]
                        }
                    >
                        <Project
                            project={myProject}
                            isFetching={isFetching}
                            lang={lang}
                            callCookies={(bool) => callCookies(bool)}
                        />
                    </PageTitle>
                }
                contentful
            />
        </div>
    );
};
export default ProjectRoot;
