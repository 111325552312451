import * as React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    band: {
        // fontSize: '0.75rem'
    },
    grid: {
        textAlign: "left",
    },
    parentGrid: {
        display: "flex",
        verticalAlign: "middle",
        alignItems: "center",
        minHeight: "36px",
    },
    text: {
        display: "flex", //- important vor vertical middle
        verticalAlign: "middle",
        height: "auto",
        alignItems: "center",
    },
    alert: {
        // color: 'red',
        fontStyle: "italic",
    },
    wrapper: {
        display: "inline",
    },
}));

const ExtendetTermin = ({ concert, lang }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid
                    item
                    xs={10}
                    sm={10}
                    md={11}
                    className={classes.parentGrid}
                >
                    <Grid container spacing={0} className={classes.grid}>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={3}
                            lg={3}
                            className={classes.text}
                        >
                            <Typography
                                variant="body2"
                                component="p"
                                className={classes.text}
                            >
                                {format(
                                    new Date(concert.fields.date),
                                    "dd.MM.yyyy | HH:mm"
                                ) + " Uhr"}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={7}
                            md={4}
                            lg={3}
                            className={classes.text}
                        >
                            <Typography variant="subtitle1">
                                {concert.fields.location}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={5}
                            lg={6}
                            className={classes.text}
                        >
                            <div className={classes.wrapper}>
                                <Typography
                                    variant="body2"
                                    component="p"
                                    color="textSecondary"
                                    className={classes.band}
                                >
                                    {concert.fields.band ? (
                                        concert.fields.band
                                    ) : (
                                        <span style={{ fontStyle: "italic" }}>
                                            tba
                                        </span>
                                    )}
                                </Typography>
                                {/* ist jetzt das künster*innen Feld */}
                                {concert.fields.alert && (
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        color="textSecondary"
                                        className={classes.alert}
                                    >
                                        {concert.fields.alert}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={1}>
                    {concert.fields.urlToLocation ? (
                        <Button
                            color="secondary"
                            target="_blank"
                            rel="noreferrer"
                            style={{ padding: "2px 8px 2px 8px" }}
                            href={concert.fields.urlToLocation}
                        >
                            {lang === "de" ? "Mehr" : "More"}
                        </Button>
                    ) : null}
                </Grid>
                {/* {concert.fields.alert && 
                    (
                    <Grid item xs={12}>
                        <Typography variant="body2" component="p" color="textSecondary" className={classes.alert}>
                            {concert.fields.alert}
                        </Typography>
                    </Grid>)
                } */}
            </Grid>
        </div>
    );
};
export default ExtendetTermin;
