import React, { useState, useEffect } from "react";
import {
    Typography,
    Grid,
    Divider,
    Hidden,
    Drawer,
    Card,
    Button,
} from "@material-ui/core";
import InteractivePlayer from "./InteractivePlayer";
import SmallInteractivePlayer from "./SmallInteractivePlayer";
import { makeStyles } from "@material-ui/core/styles";
import Youtube from "../organisation/Youtube-three";
import { useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import {
    PageTitle,
    SoundCloud,
    RichText,
    PageStyle,
    EvaluateContent,
    CookieAlert,
    Image,
} from "../organisation";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
    description: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    tracklist: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        marginTop: theme.spacing(2),
    },
    root: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    cover: {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
    },
    drawer: {
        borderTop: "0px",
    },
}));

function Album({ album, isFetching, lang, callCookies }) {
    const [play, setPlay] = useState(false);
    const classes = useStyles();
    const [cookies] = useCookies(["consent"]);
    const [cookieAlertOpen, setCookieAlertOpen] = React.useState(false);

    // !isFetching && album
    //     ? album.fields.seoKeywords &&
    //       console.log(album.fields.seoKeywords.join())
    //     : console.log("nothing");

    return (
        <PageStyle>
            <div id="top-of-album-anchor" className={classes.root}>
                <>
                    <Grid container justify={"space-between"} spacing={3}>
                        <Grid item xs>
                            <Typography
                                variant="h2"
                                style={{
                                    fontSize: "3.5rem",
                                    textAlign: "left",
                                    paddingBottom: "8px",
                                }}
                            >
                                {!isFetching && album ? (
                                    album.fields.title
                                ) : (
                                    <Skeleton
                                        animation="wave"
                                        style={{ marginBottom: "3rem" }}
                                    />
                                )}
                            </Typography>
                            {!isFetching && album && album.fields.subtitle && (
                                <Typography
                                    variant="body1"
                                    style={{
                                        textAlign: "left",
                                        marginTop: "1em",
                                        marginBottom: "1em",
                                    }}
                                >
                                    {album.fields.subtitle}
                                </Typography>
                            )}
                            <Divider />
                        </Grid>
                        {!isFetching && album && album.fields.soundcloudlink && (
                            <Hidden smDown>
                                {/* <Grid item xs> */}
                                <Grid item xs md={6} lg={5}>
                                    <InteractivePlayer
                                        image={
                                            album.fields.cover.fields.file.url
                                        }
                                        title={album.fields.songtitle}
                                        artist={album.fields.songArtist}
                                        button={
                                            cookies.consent === "1" ||
                                            cookies.consent === "3"
                                                ? () => setPlay(!play)
                                                : () => setCookieAlertOpen(true)
                                        }
                                        playing={play}
                                        url={album.fields.soundcloudlink}
                                    />
                                </Grid>
                            </Hidden>
                        )}
                    </Grid>
                    {/* <Typography variant="h5" className={classes.title}>
                        Tracklist:
                    </Typography>  */}
                    <Grid
                        container
                        elevation={0}
                        spacing={3}
                        className={classes.tracklist}
                    >
                        {/* <Hidden smDown> */}
                        <Grid item md={5} sm={5} xs={12}>
                            {" "}
                            {/* Changed from 4 4 12*/}
                            <div
                                style={{
                                    height: "0",
                                    overflow: "hidden",
                                    paddingTop: "100%",
                                    position: "relative",
                                }}
                            >
                                <Card elevation={0}>
                                    {!isFetching && album ? (
                                        <div className={classes.cover}>
                                            <Image
                                                url={
                                                    album.fields.cover.fields
                                                        .file.url
                                                }
                                                alt={album.fields.title}
                                                lazy
                                                wM={700}
                                                wT={500}
                                            />
                                        </div>
                                    ) : (
                                        // <CardMedia
                                        //     className={classes.cover}
                                        //     component="img"
                                        //     image={'https://' + album.fields.cover.fields.file.url + '?fm=webp'}
                                        //     title={album.fields.title}
                                        // />
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            className={classes.cover}
                                        />
                                    )}
                                </Card>
                            </div>
                        </Grid>
                        {/* </Hidden> */}
                        <Grid item md={7} sm={7} xs={12}>
                            {" "}
                            {/* Changed from 8 8 12*/}
                            {!isFetching && album ? (
                                <div style={{ textAlign: "left" }}>
                                    <RichText
                                        richText={album.fields.tracklist}
                                    />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        height: "0",
                                        overflow: "hidden",
                                        paddingTop: "70.3%",
                                        position: "relative",
                                    }}
                                >
                                    <Skeleton
                                        variant="rect"
                                        animation="wave"
                                        style={{ paddingBottom: "1rem" }}
                                        className={classes.cover}
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    width: "100%",
                                    position: "relative",
                                    height: "40px",
                                }}
                            >
                                <Link
                                    style={{
                                        textDecoration: `none`,
                                        position: "absolute",
                                        right: "0px",
                                    }}
                                    to={`/${lang}/form`}
                                >
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                    >
                                        {lang === "de"
                                            ? "Bestellen"
                                            : "Purchase"}
                                    </Button>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* <Typography variant="h5" className={classes.title}>
                        Musiker:
                    </Typography>  */}
                    <div className={classes.text}>
                        {!isFetching && album ? (
                            <RichText richText={album.fields.artists} />
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                height="150"
                            />
                        )}
                    </div>
                    {!isFetching && album && album.fields.youtubeVideos && (
                        <>
                            <Divider />
                            <Youtube
                                videos={album.fields.youtubeVideos}
                                lang={lang}
                                callCookies={(bool) => callCookies(bool)}
                            />
                        </>
                    )}
                    <Divider />
                    <div className={classes.description}>
                        {!isFetching && album ? (
                            <RichText richText={album.fields.description} />
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                height="150"
                            />
                        )}
                    </div>
                    {!isFetching && album && album.fields.press && (
                        <>
                            {/* <Divider /> */}
                            <div className={classes.text}>
                                <RichText richText={album.fields.press} />
                            </div>
                        </>
                    )}
                    {!isFetching && album && album.fields.soundcloudlink && (
                        <>
                            {cookies.consent === "1" ||
                            cookies.consent === "3" ? (
                                <SoundCloud
                                    url={album.fields.soundcloudlink}
                                    playing={play}
                                />
                            ) : (
                                <CookieAlert
                                    cookieAlertOpen={cookieAlertOpen}
                                    setCookieAlertOpen={(bool) =>
                                        setCookieAlertOpen(bool)
                                    }
                                    callCookies={(bool) => callCookies(bool)}
                                    lang={lang}
                                />
                            )}
                        </>
                    )}
                    {!isFetching && album && album.fields.soundcloudlink ? (
                        <>
                            <Hidden smUp>
                                {/* <div style={{height: '64px'}}/> */}
                                <Drawer
                                    classes={{
                                        paperAnchorDockedBottom: classes.drawer,
                                    }}
                                    variant="permanent"
                                    anchor="bottom"
                                >
                                    <SmallInteractivePlayer
                                        image={
                                            album.fields.cover.fields.file.url
                                        }
                                        title={album.fields.songtitle}
                                        artist={album.fields.songArtist}
                                        button={
                                            cookies.consent === "1" ||
                                            cookies.consent === "3"
                                                ? () => setPlay(!play)
                                                : () => setCookieAlertOpen(true)
                                        }
                                        playing={play}
                                        url={album.fields.soundcloudlink}
                                        view={false}
                                    />
                                </Drawer>
                            </Hidden>
                            <Hidden only={["xs", "md", "lg", "xl"]}>
                                {/* <div style={{height: '64px'}}/> */}
                                <Drawer
                                    classes={{
                                        paperAnchorDockedBottom: classes.drawer,
                                    }}
                                    variant="permanent"
                                    anchor="bottom"
                                >
                                    <SmallInteractivePlayer
                                        image={
                                            album.fields.cover.fields.file.url
                                        }
                                        title={album.fields.songtitle}
                                        artist={album.fields.songArtist}
                                        button={
                                            cookies.consent === "1" ||
                                            cookies.consent === "3"
                                                ? () => setPlay(!play)
                                                : () => setCookieAlertOpen(true)
                                        }
                                        playing={play}
                                        url={album.fields.soundcloudlink}
                                        view={true}
                                    />
                                </Drawer>
                            </Hidden>
                        </>
                    ) : null}
                </>
            </div>
        </PageStyle>
    );
}

const AlbumRoot = ({ albums, isFetching, lang, callCookies }) => {
    const [myAlbum, setalbum] = useState();
    const { album } = useParams();
    useEffect(() => {
        const getalbum = () => {
            for (var i = 0; i < albums.length; i++) {
                if (albums[i].fields.path === album) {
                    return albums[i];
                }
            }
        };
        setalbum(getalbum());
    }, [albums, album]);

    return (
        <>
            <EvaluateContent
                source={
                    myAlbum
                        ? myAlbum.fields.headerPicture
                            ? myAlbum.fields.headerPicture.fields.file.url
                            : null
                        : null
                }
                bodycomp={
                    <PageTitle
                        title={
                            myAlbum
                                ? myAlbum.fields.title + " | Johannes Mössinger"
                                : (lang === "de" ? "Musik" : "Music") +
                                  " | Johannes Mössinger"
                        }
                        description={
                            myAlbum ? myAlbum.fields.seoDescription : ""
                        }
                        keywords={
                            myAlbum
                                ? lang === "de"
                                    ? [
                                          "Album",
                                          "Jazz",
                                          "Piano",
                                          "Johannes Mössinger",
                                          "CD",
                                          "Klavier",
                                          "Musik",
                                      ].concat(myAlbum.fields.seoKeywords)
                                    : [
                                          "Album",
                                          "Jazz",
                                          "Piano",
                                          "Johannes Mössinger",
                                          "German",
                                          "Music",
                                      ].concat(myAlbum.fields.seoKeywords)
                                : lang === "de"
                                ? [
                                      "Album",
                                      "Jazz",
                                      "Piano",
                                      "Johannes Mössinger",
                                      "CD",
                                      "Klavier",
                                      "Musik",
                                  ]
                                : [
                                      "Album",
                                      "Jazz",
                                      "Piano",
                                      "Johannes Mössinger",
                                      "German",
                                      "Music",
                                  ]
                        }
                    >
                        <Album
                            album={myAlbum}
                            isFetching={isFetching}
                            lang={lang}
                            callCookies={(bool) => callCookies(bool)}
                        />
                    </PageTitle>
                }
                contentful
            />
        </>
    );
};
export default AlbumRoot;
