import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CookieDialog = ({
    cookieAlertOpen,
    setCookieAlertOpen,
    callCookies,
    lang,
    purpose,
}) => {
    const handleClose = () => {
        setCookieAlertOpen(false);
    };

    const handleCloseOpenCookies = () => {
        setCookieAlertOpen(false);
        callCookies(true);
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={cookieAlertOpen}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {lang === "de"
                        ? "Cookie Einstellung anpassen"
                        : "Adjust cookie setting"}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {lang === "de"
                            ? 'Ihre aktuell gesetzten Cookie Einstellung verhindern das die Website ihnen ein Youtube Video / einen SoundCloud Song zeigen kann. Gehen sie dafür in die Cookie Einstellungen und setzen sie einen Haken bei "Funktionale Cookies". Damit akzeptieren sie, dass Youtube, SoundCloud und andere Dritte Cookies in ihrem Browser setzen darf.'
                            : 'Your current cookie setting prevents the website from showing you a Youtube video / SoundCloud song. To do this, go to the cookie settings and check the box next to "Functional cookies". By doing so, you accept that YouTube, SoundCloud and other third parties may set cookies in your browser. '}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseOpenCookies} color="secondary">
                        {lang === "de"
                            ? "Cookie Einstellungen Öffnen"
                            : "Open Cookie Settings"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default CookieDialog;
