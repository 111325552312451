import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, Divider, Button } from '@material-ui/core';
import getAPIData from '../../api/getAPIDataNoSetState';
import { useState, useEffect } from 'react';
import { format, isAfter } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import New from '../news/New';

const useStyles = makeStyles((theme) => ({
  grid: {
    textAlign: 'left',
  },
  text: {
    display: 'flex', //- important vor vertical middle
    verticalAlign: 'middle',
    height: 'auto',
    alignItems: 'center',
  },
  alert: {
    // color: 'red',
    fontStyle: 'italic',
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  gutterTop: {
    marginTop: theme.spacing(1),
  },
}));

function Newsroom({ lang, callCookies }) {
  const classes = useStyles();
  const [tourState, setTourState] = useState({
    concerts: { data: [], isFetching: true },
    searchString: '',
  });
  const [state, setState] = useState({
    news: { data: [], isFetching: true },
    searchString: '',
  });

  useEffect(() => {
    const newsSort = (a, b) => {
      if (a.fields.old === true && b.fields.old === true) return new Date(b.fields.date) - new Date(a.fields.date);
      if (a.fields.old === true) return new Date(b.sys.createdAt) - new Date(a.fields.date);
      if (b.fields.old === true) return new Date(b.fields.old) - new Date(a.sys.createdAt);
      return new Date(b.sys.createdAt) - new Date(a.sys.createdAt);
    };
    getAPIData(
      'news',
      lang,
      (a, b) => newsSort(a, b),
      (response) => setState({ news: response })
    );
    getAPIData(
      'konzert',
      lang,
      (a, b) => new Date(a.fields.date) - new Date(b.fields.date),
      (response) => setTourState({ concerts: response })
    );
  }, [lang]);

  return (
    <Grid container spacing={3} className={classes.gutterBottom}>
      {tourState &&
        tourState.concerts &&
        tourState.concerts.data &&
        tourState.concerts.data.filter((concert) => isAfter(new Date(concert.fields.date), new Date())).length !== 0 && (
          <Grid item md={6} sm={12} style={{ height: 'auto' }}>
            <Card style={{ height: '100%' }}>
              <CardHeader title={lang === 'de' ? 'Bevorstehende Konzerte' : 'Upcoming Concerts'} />
              <CardContent>
                <Grid container spacing={1}>
                  {tourState.concerts.data.filter((concert) => isAfter(new Date(concert.fields.date), new Date())).slice(0, 5).map((concert, index) => (
                    <Grid item container key={index}>
                      {index !== 0 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={3} sm={2} md={3} lg={2}>
                        <Typography variant='body2' component='p' className={classes.text}>
                          {format(new Date(concert.fields.date), 'dd.MM.yyyy')}
                        </Typography>
                        <Typography variant='body2' component='p' className={classes.text}>
                          {format(new Date(concert.fields.date), 'HH:mm') + ' Uhr'}
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sm={8} md={7} lg={8}>
                        <Typography variant='subtitle1'>{concert.fields.location}</Typography>
                        <Typography variant='body2' component='p' color='textSecondary'>
                          {concert.fields.band ? concert.fields.band : <span style={{ fontStyle: 'italic' }}>tba</span>}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} xl={2}>
                        {concert.fields.urlToLocation ? (
                          <Button
                            color='secondary'
                            target='_blank'
                            rel='noreferrer'
                            style={{ padding: '2px 8px 2px 8px' }}
                            href={concert.fields.urlToLocation}
                          >
                            {lang === 'de' ? 'Mehr' : 'More'}
                          </Button>
                        ) : null}
                      </Grid>
                      {concert.fields.alert && (
                        <>
                          <Grid item xs={3} sm={2} md={3} lg={2}></Grid>
                          <Grid item xs={7} sm={8} md={7} lg={8}>
                            <Typography variant='body2' component='p' color='textSecondary' className={classes.alert}>
                              {concert.fields.alert}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid container className={classes.gutterTop} justifyContent='flex-end'>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='secondary'
                    target={'_blank'}
                    type='submit'
                    href={'https://johannesmoessinger.com/' + (lang === 'de' ? 'de' : 'en') +'/tour'}
                  >
                    {lang === 'de' ? 'alle Termine' : 'all Dates'}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      <Grid item md={6} sm={12} style={{ height: 'auto' }}>
        {state && state.news && state.news.data && state.news.data[0] && (
          <New lang={lang} callCookies={(bool) => callCookies(bool)} data={state.news.data[0]} />
        )}
      </Grid>
      {tourState && tourState.concerts.data.filter((concert) => isAfter(new Date(concert.fields.date), new Date())).length === 0 && (
        <Grid item md={6} sm={12} style={{ height: 'auto' }}>
          {state && state.news && state.news.data && state.news.data[1] && (
            <New lang={lang} callCookies={(bool) => callCookies(bool)} data={state.news.data[1]} />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default Newsroom;
