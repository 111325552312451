import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    img: {
        display: "block",
        borderRadius: "4px",
        overflow: "hidden",
        objectFit: "cover",
        width: "100%",
    },
}));

const Image = ({ url, alter, lazy, wM, wT, wD }) => {
    const classes = useStyles();

    return (
        <img
            className={classes.img}
            loading={lazy ? "lazy" : "eager"}
            srcSet={
                "https://" +
                url +
                "?w=" +
                wM +
                "&q=90 800w, https://" +
                url +
                "?w=" +
                wT +
                "&q=91 1200w, https://" +
                url +
                (wD ? "?w=" + wD : "") +
                " 1600w"
            }
            src={"https://" + url + "?w=" + wT + "&q=100"}
            alt={alter}
            width="100%"
            height="100%"
        />

        // <picture>
        //     {/* for desktop >900px */}
        //     {wD
        //         ? <>
        //             <source media="(min-width: 960px)" srcSet={'https://' + url + '?w=' + wD + '&fm=webp'} type="image/webp"/>
        //             <source media="(min-width: 960px)" srcSet={'https://' + url + '?w=' + wD + '&q=80'} type="image/jpeg"/>
        //         </>
        //         : <>
        //             <source media="(min-width: 960px)" srcSet={'https://' + url + '?fm=webp'} type="image/webp"/>
        //             <source media="(min-width: 960px)" srcSet={'https://' + url + '?q=80'} type="image/jpeg"/>
        //         </>}
        //     {/* for tablet <900px */}
        //     <source media="(min-width: 600px)" srcSet={'https://' + url + '?w=' + wT + '&fm=webp'} type="image/webp"/>
        //     <source media="(min-width: 6000px)" srcSet={'https://' + url + '?w=' + wT + '&q=80'} type="image/jpeg"/>
        //     {/* for mobile <600px */}
        //     <source srcSet={'https://' + url + '?w=' + wM + '&fm=webp'} type="image/webp"/>
        //     <source srcSet={'https://' + url + '?w=' + wM + '&q=80'} type="image/jpeg"/>
        //     {/* legacy browser support */}
        //     <img
        //         className={classes.img}
        //         loading={lazy ? 'lazy' : 'eager'}
        //         src={'https://' + url + '?w=' + wT + '&q=80'}
        //         alt={alter}
        //         width='100%'
        //         height='100%'/>
        // </picture>
    );
};
export default Image;
