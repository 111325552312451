import React, { useState, useEffect } from "react";
import Project from "./Project";
import Projects from "./Projects";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import getAPIData from "../../api/getAPIDataNoSetState";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/Projekte-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/Projekte-Kopfbild-s.jpg'

export default function MusicRoot({ lang, callCookies }) {
    const [state, setState] = useState({
        projects: { data: [], isFetching: true },
        searchString: "",
    });
    let match = useRouteMatch();

    useEffect(() => {
        getAPIData(
            "projects",
            lang,
            (a, b) => new Date(b.sys.createdAt) - new Date(a.sys.createdAt),
            (response) => setState({ projects: response })
        );
    }, [lang]);
    return (
        <>
            <Switch>
                <Route
                    path={`${match.url}/:project`}
                    children={
                        <Project
                            projects={state.projects.data}
                            isFetching={state.projects.isFetching}
                            lang={lang}
                            callCookies={(bool) => callCookies(bool)}
                        />
                    }
                />
                <Route exact path={match.url}>
                    <EvaluateContent
                        source={"Projekte-Kopfbild.jpg"}
                        bodycomp={
                            <Projects
                                content={state.projects.data}
                                isFetching={state.projects.isFetching}
                                lang={lang}
                                callCookies={(bool) => callCookies(bool)}
                            />
                        }
                    />
                </Route>
            </Switch>
        </>
    );
}
