import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, Typography } from "@material-ui/core/";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useCookies } from "react-cookie";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        marginBottom: theme.spacing(2),
    },
}));
function createData(
    domain,
    name,
    type,
    category,
    duration,
    description,
    active
) {
    return { domain, name, type, category, duration, description, active };
}

// function listCookies() {
//     var theCookies = document.cookie.split(';');
//     var aString = '';
//     for (var i = 1 ; i <= theCookies.length; i++) {
//         aString += i + ' ' + theCookies[i-1] + "\n";
//     }
//     return aString;
// }

export default function BasicTable({ lang }) {
    const classes = useStyles();
    const [cookies] = useCookies(["consent"]);
    const rows = [
        createData(
            "www.johannesmoessinger.com",
            "consent",
            "essential",
            "persistent",
            lang === "de" ? "1 Jahr" : "1 year",
            lang === "de"
                ? "Cookie Präferenzen"
                : "saving the cookie preferation",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "CONSENT",
            "functional",
            "persistent",
            lang === "de" ? "1 Jahr" : "1 year",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "YSC",
            "functional",
            "session",
            "-",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "GPS",
            "functional",
            "persistent",
            lang === "de" ? "30 Minuten" : "30 minutes",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "PREF",
            "functional",
            "persisitent",
            lang === "de" ? "8 Monate" : "8 months",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "1P_JAR",
            "functional",
            "persisitent",
            lang === "de" ? "1 Monat" : "1 month",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "VISITOR_INFO1_LIVE",
            "functional",
            "persistent",
            lang === "de" ? "6 Monate" : "6 months",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "youtube.com",
            "NID",
            "functional",
            "persisitent",
            lang === "de" ? "6 Monate" : "6 months",
            lang === "de" ? "Youtube einbettung" : "embed Youtube",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "soundcloud.com",
            "sclocale",
            "functional",
            "persistent",
            lang === "de" ? "1 Jahr" : "1 year",
            lang === "de" ? "SoundCloud einbettung" : "embed SoundCloud",
            typeof cookies.consent !== "undefined"
        ),
        createData(
            "www.johannesmoessinger.com",
            "_ga",
            "analysis",
            "persistent",
            lang === "de" ? "1 Jahr" : "1 year",
            "Google Analytics",
            typeof cookies._ga !== "undefined"
        ),
        createData(
            "www.johannesmoessinger.com",
            "_gid",
            "analysis",
            "persistent",
            lang === "de" ? "1 Tag" : "1 day",
            "Google Analytics",
            typeof cookies._gid !== "undefined"
        ),
        createData(
            "www.johannesmoessinger.com",
            "_gat",
            "analysis",
            "persistent",
            lang === "de" ? "2 Jahre" : "2 years",
            "Google Analytics",
            typeof cookies._gat !== "undefined"
        ),
    ];
    return (
        <>
            <Typography variant="h6" component="h3" gutterBottom>
                {lang === "de"
                    ? "Auflistung aller Cookies"
                    : "List of all cookies"}
            </Typography>
            <TableContainer component={Paper} className={classes.root}>
                <Table
                    size="small"
                    className={classes.table}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {lang === "de" ? "Domain" : "Domain"}
                            </TableCell>
                            <TableCell align="left">
                                {lang === "de" ? "Name" : "Name"}
                            </TableCell>
                            <TableCell align="left">
                                {lang === "de" ? "Kategorie" : "Category"}
                            </TableCell>
                            <TableCell align="left">
                                {lang === "de" ? "Typ" : "Type"}
                            </TableCell>
                            <TableCell align="left">
                                {lang === "de" ? "Dauer" : "Duration"}
                            </TableCell>
                            <TableCell align="left">
                                {lang === "de" ? "Beschreibung" : "Purpose"}
                            </TableCell>
                            <TableCell align="left">
                                {lang === "de" ? "Aktiv" : "Active"}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.domain}
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">
                                    {row.category}
                                </TableCell>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">
                                    {row.duration}
                                </TableCell>
                                <TableCell align="left">
                                    {row.description}
                                </TableCell>
                                <TableCell align="left">
                                    {row.domain ===
                                    "www.johannesmoessinger.com" ? (
                                        row.active ? (
                                            <Check fontSize="small" />
                                        ) : (
                                            <Clear fontSize="small" />
                                        )
                                    ) : lang === "de" ? (
                                        "unbekannt"
                                    ) : (
                                        "unknown"
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
