import * as React from "react";
import ReactPlayer from "react-player/youtube";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    video: {
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(2),
        // width: '100%',
    },
}));

const Youtube = ({ url, small, big }) => {
    const classes = useStyles();

    return (
        <>
            {small ? (
                <div className={classes.video}>
                    <ReactPlayer height="30vh" width="auto" url={url} light />
                </div>
            ) : big ? (
                <div className={classes.video}>
                    <ReactPlayer height="50vh" width="auto" url={url} light />
                </div>
            ) : (
                <div className={classes.video}>
                    <ReactPlayer width="auto" url={url} light />
                </div>
            )}
        </>
    );
};
export default Youtube;
