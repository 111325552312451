import * as React from "react";
import PageStyle from "../organisation/PageStyle";
// import { makeStyles } from "@material-ui/core/styles";
import HomePage from "./HomePage";
// import RichText from "../organisation/RichText";
// import { Skeleton } from "@material-ui/lab";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginBottom: theme.spacing(1),
//     },
//     abstract: {
//         marginTop: theme.spacing(2),
//     },
// }));

const Home = ({ home, concerts, isFetching, lang, callCookies }) => {
    // const classes = useStyles();
    return (
        <PageStyle>
            {/* <div className={classes.abstract}>
                {isFetching ? (
                    <>
                        <Skeleton
                            style={{ marginTop: "1em" }}
                            variant="text"
                            animation="wave"
                        />
                        <Skeleton
                            style={{ marginBottom: "1em" }}
                            variant="text"
                            animation="wave"
                        />
                    </>
                ) : (
                    <>
                        {home && (
                            <>
                                <RichText richText={home.fields.opener} />
                            </>
                        )}
                    </>
                )}
            </div> */}
            <>
                <HomePage
                    home={home}
                    concerts={concerts}
                    isFetching={isFetching}
                    lang={lang}
                    callCookies={(bool) => callCookies(bool)}
                />
            </>
        </PageStyle>
    );
};
export default Home;
