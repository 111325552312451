import React, { Component } from "react";
import AlbumCover from "./AlbumCover";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { PageStyle } from "../organisation";
import PropTypes from "prop-types";

const styles = (theme) => ({
    root: {
        paddingTop: theme.spacing(2),
    },
    grid: {
        // maxWidth: 'none', //changed when changing from CardMedia to Image
    },
});

class CoverFlow extends Component {
    constructor(props) {
        super();
        this.props = props;
    }

    handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            "#back-to-top-anchor"
        );
        if (anchor) {
            //   anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
            anchor.scrollIntoView({ block: "center" });
        }
    };

    render() {
        const { classes, lang } = this.props;

        return (
            <PageStyle>
                <div className={classes.root}>
                    {/* <Typography variant="h2" style={{textAlign: 'left', paddingBottom: '8px'}}>
                        Alben
                    </Typography> */}
                    {this.props.isFetching ? (
                        <>
                            <Grid container spacing={3}>
                                {[...new Array(6)].map((i, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xs={6}
                                        sm={4}
                                        md={4}
                                        lg={3}
                                        xl={3}
                                        classes={{ root: classes.grid }}
                                    >
                                        <div
                                            style={{
                                                height: "0",
                                                overflow: "hidden",
                                                paddingTop: "100%",
                                                position: "relative",
                                            }}
                                        >
                                            <Skeleton
                                                variant="rect"
                                                animation="wave"
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    ) : (
                        <>
                            {this.props.content ? (
                                <div style={{ flexGrow: 1 }}>
                                    <Grid container spacing={3}>
                                        {this.props.content.map((tile) => (
                                            <Grid
                                                key={tile.sys.createdAt}
                                                item
                                                xs={6}
                                                sm={4}
                                                md={4}
                                                lg={3}
                                                xl={3}
                                                classes={{ root: classes.grid }}
                                            >
                                                <div
                                                    onClick={this.handleClick}
                                                    role="presentation"
                                                >
                                                    <AlbumCover
                                                        album={tile}
                                                        chooseAlbum={(x) =>
                                                            this.props.chooseAlbum(
                                                                x
                                                            )
                                                        }
                                                        lang={lang}
                                                    />
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            ) : (
                                "No Albums Found"
                            )}
                        </>
                    )}
                </div>
            </PageStyle>
        );
    }
}

CoverFlow.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CoverFlow);
