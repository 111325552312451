import React, { useState, useEffect } from "react";
import Impressum from "./Impressum";
import getAPIData from "../../api/getAPIDataNoSetState";
// import evaluateContent from '../organisation/evaluateContent';

export default function ImpressumRoot({ lang }) {
    const [state, setState] = useState({
        impressum: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        getAPIData(
            "impressum",
            lang,
            (a, b) => 0,
            (response) => setState({ impressum: response })
        );
    }, [lang]);
    return (
        <>
            {/* {evaluateContent(null,<Impressum data={state.impressum.data[0]} isFetching={state.impressum.isFetching} lang={lang}/>)} */}
            <Impressum
                data={state.impressum.data[0]}
                isFetching={state.impressum.isFetching}
                lang={lang}
            />
        </>
    );
}
