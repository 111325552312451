import * as React from "react";
import ExtendetTermin from "./ExtendetTermin";
import Pagestyle from "../organisation/PageStyle";
import {
    Divider,
    makeStyles,
    Typography,
    Grid,
    Card,
    CardMedia,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { isAfter } from "date-fns";
import tour_1 from "./Tour_Picture_1.jpg";
import tour_2 from "./Tour_Picture_2.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    skeleton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    pictures: {
        marginTop: theme.spacing(2),
    },
}));

const Tour = ({ concerts, isFetching, lang }) => {
    const classes = useStyles();
    return (
        <Pagestyle>
            <div className={classes.root}>
                {isFetching ? (
                    <>
                        <Divider />
                        <Skeleton
                            className={classes.skeleton}
                            variant="text"
                            animation="wave"
                        />
                        <Divider />
                        <Skeleton
                            className={classes.skeleton}
                            variant="text"
                            animation="wave"
                        />
                        <Divider />
                        <Skeleton
                            className={classes.skeleton}
                            variant="text"
                            animation="wave"
                        />
                        <Divider />
                    </>
                ) : (
                    <>
                        {concerts ? (
                            <div>
                                {concerts.filter((concert) =>
                                    isAfter(
                                        new Date(concert.fields.date),
                                        new Date()
                                    )
                                ).length !== 0 ? (
                                    <>
                                        <Divider />
                                        {concerts
                                            .filter((concert) =>
                                                isAfter(
                                                    new Date(
                                                        concert.fields.date
                                                    ),
                                                    new Date()
                                                )
                                            )
                                            .map((concert) => (
                                                <div
                                                    key={concert.sys.createdAt}
                                                >
                                                    <ExtendetTermin
                                                        concert={concert}
                                                        lang={lang}
                                                    />
                                                    <Divider />
                                                </div>
                                            ))}
                                    </>
                                ) : (
                                    <Typography>
                                        {lang === "de"
                                            ? "Konzert- und Tourdaten werden in Kürze aktualisiert."
                                            : "Concert and tour dates will be update soon."}
                                    </Typography>
                                )}
                            </div>
                        ) : null}
                    </>
                )}
                <Grid container spacing={3} className={classes.pictures}>
                    <Grid item key={1} xs={12} sm={6} md={6}>
                        <Card elevation={0} variant="elevation">
                            <CardMedia
                                className={classes.cover}
                                component="img"
                                image={tour_1}
                                title="Tour Live Picture"
                            />
                        </Card>
                    </Grid>
                    <Grid item key={2} xs={12} sm={6} md={6}>
                        <Card elevation={0} variant="elevation">
                            <CardMedia
                                className={classes.cover}
                                component="img"
                                image={tour_2}
                                title="Tour Live Picture"
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Pagestyle>
    );
};
export default Tour;
