import React from "react";
import {
    List,
    ListItem,
    Container,
    Hidden,
    Typography,
    Toolbar,
    makeStyles,
} from "@material-ui/core";
import SideDrawer from "./SideDrawer";
import { useLocation, Link } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import LinkButton from "./LinkButton";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        // fontSize: 'calc(12px + 2.4vw)',
        fontSize: theme.typography.fontSize * 1.8,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    appBar: {
        backgroundColor: theme.palette.background.default,
    },
    navDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: "center",
        padding: "0",
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: theme.palette.text.primary,
    },
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    link: {
        borderRadius: '4px',
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const location = useLocation();
    const [loc, setLoc] = React.useState();
    React.useEffect(() => {
        setLoc(location.pathname);
    }, [location.pathname]);

    const navLinks = [
        { titleDE: `start`, titleEN: `home`, path: `/${props.lang}/home` },
        { titleDE: `über`, titleEN: `about`, path: `/${props.lang}/about` },
        { titleDE: `musik`, titleEN: `music`, path: `/${props.lang}/music` },
        { titleDE: `medien`, titleEN: `media`, path: `/${props.lang}/media` },
        { titleDE: `news`, titleEN: `news`, path: `/${props.lang}/news` },
        {
            titleDE: `projekte`,
            titleEN: `projects`,
            path: `/${props.lang}/projects`,
        },
        { titleDE: `tour`, titleEN: `tour`, path: `/${props.lang}/tour` },
    ];

    return (
        <Toolbar>
            <Container
                style={{ padding: 0 }}
                className={classes.navbarDisplayFlex}
                maxWidth={false}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    className={classes.title}
                >
                    <Link to={`/${props.lang}/home`} className={classes.title}>
                        {/* <Link underline={"none"} href={"/" + props.lang + "/home"}> */}
                        Johannes Mössinger
                        {/* </Link> */}
                    </Link>
                </Typography>
                <Hidden mdDown>
                    <List
                        component="nav"
                        aria-labelledby="main navigation"
                        className={classes.navDisplayFlex}
                    >
                        {/* <LanguageSelect lang={props.lang} /> */}
                        {navLinks.map(({ titleDE, titleEN, path }) => (
                            // <a href={path} key={title} className={classes.linkText}>
                            <Link
                                key={titleDE}
                                to={path}
                                className={classes.linkText}
                            >
                                {/* WAAAYYY FASTER!!!*/}
                                <ListItem button className={classes.link}>
                                    {props.lang === "de" ? (
                                        <LinkButton
                                            currentpath={loc}
                                            title={titleDE}
                                            path={path}
                                            classes={classes}
                                        />
                                    ) : (
                                        <LinkButton
                                            currentpath={loc}
                                            title={titleEN}
                                            path={path}
                                            classes={classes}
                                        />
                                    )}
                                    {/* <ListItemText primary={title} /> */}
                                </ListItem>
                            </Link>
                            // </a>
                        ))}
                        <LanguageSelect
                            lang={props.lang}
                            setLang={(x) => props.setLang(x)}
                        />
                    </List>
                </Hidden>
                <Hidden lgUp>
                    <SideDrawer
                        navLinks={navLinks}
                        lang={props.lang}
                        setLang={(x) => props.setLang(x)}
                    />
                </Hidden>
            </Container>
        </Toolbar>
    );
}
