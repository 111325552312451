import * as contentful from 'contentful';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const client = contentful.createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN,
})


const getApiData = async (name, language, sortMethod, setState) => {

    client.getEntries({
            content_type: name,
            locale: language,
        })
        .then((response) => {
            const ret = response.items.sort(sortMethod);
            setState({
                data: ret,
                isFetching: false
            });
        })
        .catch((error) => {
            console.log("error occured while fetching data");
            console.log(error);
        })
}

export default getApiData