import React from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    CardActions,
    CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import songbook from "./songbook.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: theme.spacing(2),
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    pictures: {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    icon: {
        verticalAlign: "text-bottom",
    },
    card: {
        background: "none",
    },
    item: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        margin: "auto",
    },
    selectabletext: {
        MozUserSelect: "text",
        WebkitUserSelect: "text",
        msUserSelect: "text",
        fontSize: "0.9rem",
    },
}));

const Songbook = ({ lang }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={12}>
            <Card raised variant="elevation" className={classes.card}>
                <CardContent>
                    <div className={classes.item}>
                        <Typography
                            variant="h5"
                            component="h3"
                            className={classes.main}
                        >
                            Songbook
                        </Typography>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Card raised variant="elevation">
                                <CardMedia
                                    className={classes.pictures}
                                    component="img"
                                    image={songbook}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={classes.selectabletext}>
                                {lang === "de"
                                    ? "44 Kompositionen"
                                    : "44 Compositions"}
                                <br />
                                {lang === "de"
                                    ? "Originale und Leadsheets"
                                    : "Originals and Leadsheets"}
                                <br />
                                {lang === "de"
                                    ? "Notensatz mit Sibelius"
                                    : "Notation with Sibelius"}
                                <br />
                                {lang === "de"
                                    ? "Einzelstimme und Arrangements"
                                    : "Singleparts and Arrangements"}
                                <br />
                                <br />
                                {lang === "de"
                                    ? "122 Seiten, Paperback"
                                    : "122 Pages, Paperback"}
                                <br />
                                {lang === "de" ? "24,99 €" : "24,99 €"}
                                <br />
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    {/* <div style={{width: '100%', position: 'relative', height: '40px'}}>
                        <Button 
                            style={{ position: 'absolute', right: '0px'}} 
                            color='secondary' 
                            variant='contained'
                            rel="noopener noreferrer"
                            href={"mailto:info@johannesmoessinger.de?Subject=Songbook%Bestellung"}>
                            {lang === 'de' ? 'Songbook bestellen' : 'Order Songbook'}
                        </Button>
                    </div> */}
                    <div
                        style={{
                            width: "100%",
                            position: "relative",
                            height: "40px",
                        }}
                    >
                        <Link
                            style={{
                                textDecoration: `none`,
                                position: "absolute",
                                right: "0px",
                            }}
                            to={`/${lang}/form`}
                        >
                            <Button color="secondary" variant="contained">
                                {lang === "de"
                                    ? "Songbook bestellen"
                                    : "Purchase Songbook"}
                            </Button>
                        </Link>
                    </div>
                </CardActions>
            </Card>
        </Grid>
    );
};
export default Songbook;
