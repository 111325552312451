import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import Quote from "./Quote";
import { RichText, PageStyle } from "../organisation";
import Picture from "./Picture";
import Skeleton from "@material-ui/lab/Skeleton";
import PressKit from "../snips/PressKit";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    quotes: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    press: {
        marginBottom: theme.spacing(2),
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    pictures: {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
}));

const About = ({ about, isFetching, lang }) => {
    const classes = useStyles();
    return (
        <PageStyle>
            <>
                {isFetching ? (
                    <>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className={classes.paragraph}>
                                    <Skeleton
                                        variant="rect"
                                        animation="wave"
                                        height={300}
                                    />
                                </div>
                                <Divider />
                                <div className={classes.quotes}>
                                    <Skeleton variant="text" animation="wave" />
                                    <Skeleton variant="text" animation="wave" />
                                    <Skeleton variant="text" animation="wave" />
                                </div>
                                <Divider />
                                <div className={classes.paragraph}>
                                    <Skeleton
                                        variant="rect"
                                        animation="wave"
                                        height={400}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Grid container spacing={3}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            height={300}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            height={300}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            height={300}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        {about ? (
                            <>
                                <Grid
                                    container
                                    spacing={3}
                                    className={classes.root}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                    >
                                        {/* <Typography variant="h2" component="h2" className={classes.title}>
                                            {about.fields.pageTitle}
                                        </Typography> */}
                                        <>
                                            <RichText
                                                richText={
                                                    about.fields.description
                                                }
                                            />
                                        </>
                                        {about.fields.quotes ? (
                                            <>
                                                <Divider />
                                                <div className={classes.quotes}>
                                                    {about.fields.quotes.map(
                                                        (quote) => (
                                                            <Quote
                                                                key={quote}
                                                                quote={quote}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        ) : null}
                                        <>
                                            <Divider
                                                className={classes.divider}
                                            />
                                            <RichText
                                                richText={
                                                    about.fields.biography
                                                }
                                            />
                                        </>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                    >
                                        <Grid container spacing={3}>
                                            <PressKit lang={lang} />
                                            {about.fields.pictures.map(
                                                (picture) => (
                                                    <Grid
                                                        item
                                                        key={
                                                            picture.fields.file
                                                                .fileName
                                                        }
                                                        className={
                                                            classes.pictures
                                                        }
                                                        xs={12}
                                                        sm={6}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Picture
                                                            url={
                                                                picture.fields
                                                                    .file.url
                                                            }
                                                        />
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : null}
                    </>
                )}
            </>
        </PageStyle>
    );
};
export default About;
