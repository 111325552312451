import {
    saveAs
} from 'file-saver';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';

var zip = new JSZip();
var count = 0;
var zipFilename = "J-Mössinger-Presse-Kit.zip";

const getUrls = (arr) => {
    var urls = new Array(arr.fields.pictures.length + arr.fields.documents.length)
    for (var i in arr.fields.pictures) {
        urls[i] = arr.fields.pictures[i].fields.file.url
    }
    for (var j in arr.fields.documents) {
        urls[parseFloat(j) + parseFloat(arr.fields.pictures.length)] = arr.fields.documents[j].fields.file.url
    }
    return urls;
}
const getFileNames = (arr) => {
    var filenames = new Array(arr.fields.pictures.length + arr.fields.documents.length)
    for (var i in arr.fields.pictures) {
        filenames[i] = arr.fields.pictures[i].fields.file.fileName
    }
    for (var j in arr.fields.documents) {
        filenames[parseFloat(j) + parseFloat(arr.fields.pictures.length)] = arr.fields.documents[j].fields.file.fileName
    }
    return filenames;
}

const createArray = (arr) => {
    const daten = new Array(arr.fields.pictures.length + arr.fields.documents.length);

    const urls = getUrls(arr);
    const filenames = getFileNames(arr);
    for (var i in urls) {
        daten[i] = {
            url: urls[i],
            name: filenames[i]
        }
    }

    return daten
}

const downloadPressZip = (arr) => {
    const daten = createArray(arr);
    return (
        daten.forEach(function (date) {
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(date.url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                zip.file(date.name, data, {
                    binary: true
                });
                count++;
                if (count === daten.length) {
                    //  var zipFile = zip.generate({type: "blob"});
                    //  saveAs(zipFile, zipFilename);
                    zip.generateAsync({
                        type: 'blob'
                    }).then(function (content) {
                        saveAs(content, zipFilename);
                    })
                }
            });
        })
    )
}
export default downloadPressZip