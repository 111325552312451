import { createTheme } from "@material-ui/core/styles";
import { grey, blueGrey, blue } from "@material-ui/core/colors";

// import useMediaQuery from '@material-ui/core/useMediaQuery';

// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

function getTheme(theme) {
    return createTheme({
        palette: {
            type: theme.paletteType,
            primary: {
                // main: deepOrange['A400'],
                main: theme.paletteType === "light" ? blueGrey[700] : blueGrey[400], //Was 500
                // main: cyan[500],
            },
            secondary: {
                // main: deepOrange[300],
                main: blue["400"], //was cyan 500
                light: blue["600"],
                dark: blue["700"],
            },
            background: {
                paper: theme.paletteType === "dark" ? grey[900] : "#fff",
                default: theme.paletteType === "dark" ? "#121212" : "#fafafa",
            },
        },
    });
}

const theme = getTheme({
    paletteType: "dark",
});

export default theme;
