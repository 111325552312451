import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
// import { Typography, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
    },
    link: {
        "&:link": {
            textDecoration: "none",
            color: theme.palette.secondary.light,
        },
        "&:visited": {
            textDecoration: "none",
            color: theme.palette.secondary.dark,
        },
        "&:hover": {
            textDecoration: "none",
            color: theme.palette.secondary.dark,
        },
    },
    paragraph: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // hyphens: 'auto',
        // textAlign: 'justify',
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const Link = ({ link, click }) => {
    const classes = useStyles();
    return (
        <a
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={click}
        >
            {link}
        </a>
    );
};

const Paragraph = ({ children }) => {
    const classes = useStyles();
    return <Typography className={classes.paragraph}>{children}</Typography>;
};

const Heading1 = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography variant="h4" component="h4" className={classes.title}>
            {children}
        </Typography>
    );
};

const options = {
    // renderMark: {
    //     [MARKS.ITALIC]: text => text
    // },
    renderNode: {
        [INLINES.HYPERLINK]: (node) => (
            <Link link={node.content[0].value} click={node.data.uri}></Link>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Paragraph>{children}</Paragraph>
        ),
        [BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
        [BLOCKS.HR]: () => <Divider />,
    },
    renderText: (text) => {
        return text.split("\n").reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
};

const RichText = ({ richText }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {documentToReactComponents(richText, options)}
        </div>
    );
};
export default RichText;
