import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Youtube from "../organisation/Youtube";
import RichText from "../organisation/RichText";
import { Paper, Typography, Card, Button } from "@material-ui/core";
import { useRouteMatch, Link } from "react-router-dom";
import { Image } from "../organisation";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    content: {
        height: "100%",
        padding: theme.spacing(2),
        textAlign: "left",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontSize: "1.5rem",
    },
    subtitle: {
        // marginBottom:  theme.spacing(2),
        fontWeight: "bold",
    },
    subcontent: {
        marginTop: theme.spacing(2),
    },
    linkText: {
        color: theme.palette.text.primary,
        textDecoration: `none`,
    },
}));

const ProjectTile = ({ data, lang, callCookies }) => {
    const classes = useStyles();
    let match = useRouteMatch();
    return (
        <div className={classes.root}>
            <Paper style={{ height: "100%" }}>
                <div className={classes.content}>
                    <Link
                        className={classes.linkText}
                        to={`${match.path}/${data.fields.path}`}
                    >
                        <Typography
                            variant="h5"
                            component="h2"
                            className={classes.title}
                        >
                            {data.fields.title}
                        </Typography>
                        {data.fields.musicians ? (
                            <Typography
                                variant="body2"
                                component="h4"
                                className={classes.subtitle}
                            >
                                {data.fields.musicians}
                            </Typography>
                        ) : null}
                    </Link>
                    <div className={classes.subcontent}>
                        <Card elevation={0} variant="elevation">
                            {data.fields.youtubeVideos ? (
                                <>
                                    <Youtube
                                        url={data.fields.youtubeVideos[0]}
                                        fallbackImage={
                                            data.fields.placeholder.fields.file
                                                .url + "?fm=jpg&fl=progressive"
                                        }
                                        lang={lang}
                                        callCookies={(bool) =>
                                            callCookies(bool)
                                        }
                                        noButton={true}
                                    />
                                </>
                            ) : (
                                <>
                                    {data.fields.pictures ? (
                                        <Image
                                            url={
                                                data.fields.pictures[0].fields
                                                    .file.url
                                            }
                                            alt={data.fields.title}
                                            wM={600}
                                            wT={600}
                                            wD={800}
                                        />
                                    ) : // <CardMedia
                                    //     className={classes.cover}
                                    //     component="img"
                                    //     image={'https://' + data.fields.pictures[0].fields.file.url + '?fm=webp'}
                                    //     title={data.fields.title}
                                    // />
                                    null}
                                </>
                            )}
                        </Card>
                    </div>
                    <div className={classes.subcontent}>
                        <RichText richText={data.fields.abstract} />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            position: "relative",
                            minHeight: "40px",
                            flex: 1,
                        }}
                    >
                        <Link
                            style={{
                                textDecoration: `none`,
                                position: "absolute",
                                right: "0px",
                                bottom: "0px",
                            }}
                            to={`${match.path}/${data.fields.path}`}
                        >
                            <Button size="large" color="secondary">
                                {lang === "de" ? "weiterlesen" : "read more"}
                            </Button>
                        </Link>
                    </div>
                </div>
            </Paper>
        </div>
    );
};
export default ProjectTile;
