import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { Typography, Divider, Grid, Hidden } from "@material-ui/core";
import SubscribeToNewsletter from "./SubscribeToNewsletter";
import { SocialMedia, PageStyle } from "../organisation";
import { matchPath } from "react-router";
import { useLocation, Link } from "react-router-dom";
import { PressKit, Songbook } from "../snips";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    footer: {
        textAlign: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
    },
    secondFooter: {
        textAlign: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginLeft: "auto",
        marginRight: "auto",
    },
    link: {
        color: theme.palette.secondary.main,
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    secondDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    middle: {
        textAlign: "center",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

const emptySpace = (currentpath) => {
    if (
        !!matchPath(currentpath, "/de/home") ||
        !!matchPath(currentpath, {
            path: "/de/music/:album",
            exact: true,
            strict: true,
        }) ||
        !!matchPath(currentpath, "/en/home") ||
        !!matchPath(currentpath, {
            path: "/en/music/:album",
            exact: true,
            strict: true,
        })
    ) {
        return (
            <Hidden mdUp>
                <div style={{ height: "64px" }} />
            </Hidden>
        );
    } else {
        return null;
    }
};

const Footer = ({ lang, callCookies, themeMode }) => {
    const classes = useStyles();
    const location = useLocation();
    const [loc, setLoc] = React.useState();
    React.useEffect(() => {
        setLoc(location.pathname);
    }, [location.pathname]);

    return (
        // grey[900] in dark mode
        <PageStyle background={themeMode === 'dark' ? grey[900] : grey[200]} noMaxWidth>
            {/*changed from 300*/}
            <div className={classes.root}>
                <SocialMedia />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        {/* <Typography variant="h5" className={classes.footer}>
                            {lang === 'de' ? 'Newsletter abonnieren' : 'Subscribe to newsletter'}
                        </Typography> */}
                        <SubscribeToNewsletter
                            className={classes.input}
                            lang={lang}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <PressKit lang={lang} short />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Songbook lang={lang} />
                    </Grid>
                </Grid>
                <Divider className={classes.secondDivider} />
                <Typography variant="body2" className={classes.secondFooter}>
                    {"© Copyright " +
                        new Date().getFullYear() +
                        " Johannes Mössinger. All rights reserved. Website by Quirin Weninger | "}
                    <Link
                        to={`/${lang}/impressum`}
                        style={{ textDecoration: "none" }}
                        className={classes.link}
                    >
                        {lang === "de" ? "Impressum" : "Contact"}
                    </Link>
                    {" | "}
                    <Link
                        to={`/${lang}/gdpr`}
                        style={{ textDecoration: "none" }}
                        className={classes.link}
                    >
                        {lang === "de" ? "Datenschutz" : "Legal Disclosure"}
                    </Link>
                    {" | "}
                    <span
                        onClick={() => callCookies(true)}
                        className={classes.link}
                        style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                        {lang === "de"
                            ? "Cookie Einstellungen"
                            : "Cookie Settings"}
                    </span>
                </Typography>
                {emptySpace(loc)}
            </div>
        </PageStyle>
    );
};
export default Footer;
