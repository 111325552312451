import { ListItemText } from "@material-ui/core";
import * as React from "react";
import { matchPath } from "react-router";

const LinkButton = ({ currentpath, title, path, classes }) => {
    if (!!matchPath(currentpath, path)) {
        return <ListItemText className={classes.activeLink} primary={title} />;
    } else {
        return <ListItemText primary={title} />;
    }
};
export default LinkButton;
