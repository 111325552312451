import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: "5vw",
        paddingRight: "5vw",
        maxWidth: 1600,
        margin: "auto",
        textAlign: "justify",
        hyphens: "auto",
        position: "relative",
    },
}));
const Pagestyle = ({ children, background, noMaxWidth }) => {
    const classes = useStyles();
    const width = noMaxWidth ? "none" : 1600;
    return (
        <div
            style={{ background: background, maxWidth: width }}
            className={classes.root}
        >
            <ThemeProvider
                theme={(theme) =>
                    createTheme({
                        ...theme,
                        props: {
                            ...theme.props,
                            MuiPaper: {
                                elevation: 0,
                                // variant: "outlined",
                            },
                            MuiCard: {
                                variant: "elevation",
                                elevation: 0,
                                raised: true,
                            },
                        },
                    })
                }
            >
                {children}
            </ThemeProvider>
        </div>
    );
};
export default Pagestyle;
