import React, { useState, useEffect } from "react";
import Datenschutz from "./Datenschutz";
import getAPIData from "../../api/getAPIDataNoSetState";

export default function DSGVORoot({ lang, mounted, cookiedisk }) {
    useEffect(() => {
        cookiedisk(false);
    });
    const [state, setState] = useState({
        datenschutz: { data: [], isFetching: true },
        searchString: "",
    });
    useEffect(() => {
        getAPIData(
            "datenschutz",
            lang,
            (a, b) => 0,
            (response) => setState({ datenschutz: response })
        );
    }, [lang]);

    // cookiedisk(false);

    return (
        <>
            <Datenschutz
                data={state.datenschutz.data[0]}
                isFetching={state.datenschutz.isFetching}
                lang={lang}
            />
        </>
    );
}
