import * as React from "react";
import { Card } from "@material-ui/core";
// import { makeStyles } from '@material-ui/core/styles';
import { Image } from "../organisation";

// const useStyles = makeStyles((theme) => ({
//     media: {
//     },
//   }));

const Picture = ({ url }) => {
    // const classes = useStyles();

    return (
        <Card raised variant="elevation">
            <Image
                url={url}
                alt={"Johannes Mössinger Porträt"}
                lazy
                wM={700}
                wT={700}
                wD={800}
            />
            {/* <CardMedia
                 className={classes.media}
                 component="img"
                 image={'https://' + url + '?w=550&q=80&fm=webp'}
             /> */}
        </Card>
    );
};
export default Picture;
