import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
import { ScrollToTop } from "./components/organisation";
// import {DebugRouter as Router} from './components/organisation';

// import promise from 'redux-promise';
// const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

ReactDOM.render(
    // <React.StrictMode>
    <CookiesProvider>
        <Router>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Router>
    </CookiesProvider>,
    // </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
