import React, { useState, useEffect } from "react";
import Home from "./Home";
import getAPIData from "../../api/getAPIDataNoSetState";
import { EvaluateContent } from "../organisation";
// import header from '../header/pictures/Kopfbild/Home-Kopfbild.jpg'
// import headerS from '../header/pictures/Kopfbild-s/Home-Kopfbild-s.jpg'

export default function HomeRoot({ lang, callCookies }) {
    const [homestate, setHomeState] = useState({
        home: { data: [], isFetching: true },
        searchString: "",
    });
    const [concertstate, setConcertState] = useState({concerts: [],searchString: ''});
    // const [aboutState, setAboutState] = useState({about: [],searchString: ''});
    useEffect(() => {
        // getAPIData('about',lang,(a, b) => 0,(response) => setAboutState({about: response}))
        getAPIData(
            "home",
            lang,
            (a, b) => 0,
            (response) => setHomeState({ home: response })
        );
        getAPIData('konzert',lang,(a, b) => new Date(a.fields.date) - new Date(b.fields.date),(response) => setConcertState({concerts: response}))
    }, [lang]);

    return (
        <>
            {/* <EvaluateContent source={header} bodycomp={<Home home={homestate.home.data[0]} isFetching={homestate.home.isFetching} lang={lang} callCookies={(bool) => callCookies(bool)}/>} sourceS={headerS} /> */}
            <EvaluateContent
                source={"Home-Kopfbild.jpg"}
                bodycomp={
                    <Home
                        home={homestate.home.data[0]}
                        concerts={concertstate}
                        isFetching={homestate.home.isFetching}
                        lang={lang}
                        callCookies={(bool) => callCookies(bool)}
                    />
                }
            />
        </>
    );
}
