import * as React from 'react';
import { Grid, Card, CardMedia, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Youtube, RichText, Image } from '../organisation';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  // content: {
  //     padding: theme.spacing(2),
  //     textAlign: 'left',
  //     overflow: 'hidden',
  // },
  // title: {
  //     fontSize: '1.7rem',
  //     marginBottom: theme.spacing(1),
  // },
  // subtitle: {
  //     marginBottom:  theme.spacing(2),
  // },
  // subcontent: {
  //     marginTop: theme.spacing(4),
  // },
  cover: {
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  },
}));

const ddmmyyyy = (date) => {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();
  return [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join('.');
};

const oldDate = (data) => {
  if (data.fields.old === true) {
    return ddmmyyyy(new Date(data.fields.date));
  } else {
    return format(new Date(data.sys.createdAt), 'dd.MM.yyyy');
  }
};

const New = ({ data, lang, callCookies }) => {
  const classes = useStyles();
  const needGrid = data.fields.headpicture || data.fields.content;
  return (
    <div className={classes.root}>
      <Card style={{ height: '100%' }}>
        {/* <Card> */}
        <CardHeader
          titleTypographyProps={{ align: 'left' }}
          title={data.fields.title}
          subheader={oldDate(data) + (data.fields.subtitle ? ' — ' + data.fields.subtitle : '')}
        />

        {data.fields.youtube ? (
          <CardMedia className={classes.cover}>
            <div className={classes.youtube}>
              <Youtube url={data.fields.youtube} lang={lang} callCookies={(bool) => callCookies(bool)} />
            </div>
          </CardMedia>
        ) : (
          <>
            {data.fields.header ? (
              <CardMedia className={classes.cover}>
                <Card>
                  <Image url={data.fields.header.fields.file.url} alt={data.fields.title} wM={600} wT={960} wD={960} />
                  {/* <CardMedia
                                    component="img"
                                    image={'https://' + data.fields.header.fields.file.url + '?fm=webp'}
                                    title={data.fields.title}
                                /> */}
                </Card>
              </CardMedia>
            ) : null}
          </>
        )}
        {needGrid ? (
          <CardContent>
            <Grid container spacing={3}>
              {data.fields.headpicture ? (
                <Grid item xs={12} sm={6} md={12} lg={6} xl={6}>
                  <Card elevation={0} variant='elevation'>
                    <Image url={data.fields.headpicture.fields.file.url} alt={data.fields.title} wM={600} wT={800} wD={600} />
                    {/* <CardMedia
                                            className={classes.cover}
                                            component="img"
                                            image={'https://' + data.fields.headpicture.fields.file.url + '?fm=webp'}
                                            title={data.fields.title}
                                            /> */}
                  </Card>
                </Grid>
              ) : null}
              {data.fields.content ? (
                <Grid item xs>
                  <>
                    <RichText richText={data.fields.content} />
                  </>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        ) : null}

        {/* <div className={classes.content}> */}

        {/* <Typography variant="h5" component="h2" className={classes.title}>
                        {data.fields.title}
                    </Typography>
                    
                    {data.fields.subtitle ? 
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.subtitle}>
                            {oldDate(data)} — {data.fields.subtitle}
                        </Typography> : 
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.subtitle}>
                            {oldDate(data)}
                        </Typography>
                    } */}
        {/* <Divider className={classes.divider}/> */}
        {/* <div className={classes.subcontent}>
                        
                    </div> */}
        {/* </div> */}
      </Card>
    </div>
  );
};
export default New;
