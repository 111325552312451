import React from "react";
import { Card, ThemeProvider } from "@material-ui/core";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { Link, useRouteMatch } from "react-router-dom";
import Image from "../organisation/Image";

const theme = createTheme({
    props: {
        MuiPaper: {
            variant: "elevation",
            elevation: 1,
        },
        MuiCard: {
            variamt: "elevation",
            elevation: 1,
            raised: true,
        },
    },
});

const useStyles = makeStyles({
    root: {
        maxWidth: "none",
        margin: "auto",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    },
    cursor: {
        cursor: "pointer",
    },
    media: {},
});

const AlbumCover = (props) => {
    const [shadow, setShadow] = React.useState(0);
    const classes = useStyles();
    const { path } = useRouteMatch();

    // const history = useHistory();

    // function handleClick(currentpath) {
    //   history.push(`${path}/${currentpath}`);
    // }
    const onMouseOver = () => setShadow(24);
    const onMouseOut = () => setShadow(0);

    return (
        <>
            <Link to={`${path}/${props.album.fields.path}`}>
                <ThemeProvider theme={theme}>
                    <Card
                        onMouseOver={() => onMouseOver()}
                        onMouseOut={() => onMouseOut()}
                        elevation={shadow}
                        className={classes.root}
                    >
                        <Image
                            url={props.album.fields.cover.fields.file.url}
                            alt={props.album.fields.title}
                            wM={300}
                            wT={400}
                            wD={700}
                        />
                        {/* <CardMedia
                component="img"
                className={classes.media}
                image={'https://' + props.album.fields.cover.fields.file.url + '?w=500&h=500&fm=webp'}
                title={props.album.fields.title}
              /> */}
                    </Card>
                </ThemeProvider>
            </Link>
        </>
    );
};
export default AlbumCover;
