import * as React from "react";
import { Divider, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Facebook, YouTube, Email, Share } from "@material-ui/icons";
import { Icon } from "@iconify/react";
import spotifyIcon from "@iconify/icons-mdi/spotify";

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingBottom: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "auto",
        width: "100%",
    },
    buttonGroup: {
        // margin: 'auto',
    },
    button: {
        padding: theme.spacing(2),
    },
    facebook: {
        padding: theme.spacing(2),
        "&:hover": {
            color: "#3b5998",
        },
    },
    youtube: {
        padding: theme.spacing(2),
        "&:hover": {
            color: "#FF0000",
        },
    },
    spotify: {
        padding: theme.spacing(2),
        "&:hover": {
            color: "#1DB954",
        },
    },
    divider: {
        flex: "1",
    },
}));

const shareButton = () => {
    console.log(navigator);
    if (navigator.share !== undefined) {
        navigator
            .share({
                title: "Johannes Mössinger",
                url: "https://www.johannesmoessinger.com",
            })
            .then(() => console.log("sharing..."))
            .catch((error) => console.log(error));
    } else {
        alert("Desktop");
    }
};
const ShareButton = () => {
    const classes = useStyles();
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return (
            <>
                <IconButton
                    className={classes.facebook}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/johannes.mossinger.56"
                    aria-label="facebook"
                >
                    <Facebook />
                </IconButton>
                <IconButton
                    className={classes.youtube}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UChVWC8vU3rZjrLv1Po1HURw"
                    aria-label="youtube"
                >
                    <YouTube />
                </IconButton>
                <IconButton
                    className={classes.spotify}
                    target="_blank"
                    rel="noreferrer"
                    href="https://open.spotify.com/artist/5yHkQmxSec89BcCpLxRaae?si=gQOWTb0pTL-NH38CMUn0dg"
                    aria-label="spotify"
                >
                    <Icon icon={spotifyIcon} />
                </IconButton>
                <IconButton
                    className={classes.button}
                    href="mailto:info@johannesmoessinger.de"
                    aria-label="email"
                >
                    <Email />
                </IconButton>
                <IconButton
                    className={classes.button}
                    onClick={shareButton}
                    aria-label="share"
                >
                    <Share />
                </IconButton>
            </>
        );
    } else {
        return (
            <>
                <IconButton
                    className={classes.facebook}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/johannes.mossinger.56"
                    aria-label="facebook"
                >
                    <Facebook />
                </IconButton>
                <IconButton
                    className={classes.youtube}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UChVWC8vU3rZjrLv1Po1HURw"
                    aria-label="youtube"
                >
                    <YouTube />
                </IconButton>
                <IconButton
                    className={classes.spotify}
                    target="_blank"
                    rel="noreferrer"
                    href="https://open.spotify.com/artist/5yHkQmxSec89BcCpLxRaae?si=gQOWTb0pTL-NH38CMUn0dg"
                    aria-label="spotify"
                >
                    <Icon icon={spotifyIcon} />
                </IconButton>
                <IconButton
                    id="mail"
                    className={classes.button}
                    href="mailto:info@johannesmoessinger.de"
                    aria-label="email"
                >
                    <Email />
                </IconButton>
            </>
        );
    }
};

const SocialMedia = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Divider className={classes.divider} />
            <div className={classes.buttonGroup}>
                <ShareButton />
            </div>
            <Divider className={classes.divider} />
        </div>
    );
};
export default SocialMedia;
