// import React, { useEffect } from "react";
// import MetaTags from 'react-meta-tags';

// const Page = (props) => {
//   useEffect(() => {
//     document.title = props.title || "Johannes Mössinger - Jazzpianist";
//   }, [props.title]);
//   return (
//     <>
//       <MetaTags>
//         <meta
//           name="description"
//           content={ props.description || "Johannes Mössinger zählt zu den kreativsten Jazzmusikern in Deutschland und feierte zuletzt große Erfolge mit seinem Album „New By Two“ im Duo mit dem Shooting Star am Saxophon Joel Frahm aus NY. 2018 veröffentlichte er zudem seine Einspielung der Inventionen von J.S.Bach und unterstreicht sein Ausnahmetalent und seine Vielschichtigkeit am Klavier. Mittlerweile hat er sich einen ganz eigenen, faszinierenden Jazz-Stil angeeignet, der zum Markenzeichen seines Klavierspiels und seiner Musik wurde."}></meta>
//       </MetaTags>
//       {props.children}
//     </>
//   )
// };

// export default Page;

import { Helmet } from "react-helmet";
import React from "react";
const Page = (props) => {
    const url = `https://johannesmoessinger.com/${props.pathSlug}`;
    return (
        <>
            <Helmet
                htmlAttributes={{ lang: "de" }}
                title={props.title}
                meta={[
                    {
                        name: "description",
                        content: props.description,
                    },
                    {
                        name: "keywords",
                        content: props.keywords
                            ? props.keywords.join()
                            : ["Jazzpianist", "Musiker", "Komponist"].join(),
                    },
                ]}
                links={[
                    {
                        rel: "canonical",
                        href: url,
                    },
                ]}
            />
            {props.children}
        </>
    );
};
export default Page;
