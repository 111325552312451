import * as React from "react";
import PageStyle from "../organisation/PageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import RichText from "../organisation/RichText";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(4),
        fontSize: "3rem",
    },
}));

const Impressum = ({ data, isFetching }) => {
    const classes = useStyles();
    return (
        <PageStyle>
            <div className={classes.root}>
                {isFetching ? (
                    <>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            <Skeleton variant="text" animation="wave" />
                        </Typography>
                        <>
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                height={600}
                            />
                        </>
                    </>
                ) : (
                    <>
                        {data ? (
                            <>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    className={classes.title}
                                >
                                    {data.fields.title}
                                </Typography>
                                <>
                                    <RichText richText={data.fields.text} />
                                </>
                            </>
                        ) : null}
                    </>
                )}
            </div>
        </PageStyle>
    );
};
export default Impressum;
