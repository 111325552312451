import * as React from "react";
import Pagestyle from "../organisation/PageStyle";
import NewsGridOption from "./NewsGridOption";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";

const News = ({ news, isFetching, lang, callCookies }) => {
    const [page, setPage] = React.useState(1);
    return (
        <Pagestyle>
            <>
                {isFetching ? (
                    <Grid container spacing={3}>
                        {[...new Array(2)].map((i, index) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{ height: "auto" }}
                            >
                                <Paper style={{ height: "100%" }}>
                                    <div style={{ padding: "16px" }}>
                                        <Typography variant="h5" component="h2">
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                            />
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                            />
                                        </Typography>
                                        <Divider
                                            style={{
                                                marginTop: "1rem",
                                                marginBottom: "1rem",
                                            }}
                                        />
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            height={350}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <>
                        {news && (
                            <NewsGridOption
                                news={news}
                                variant={1}
                                page={page}
                                setPage={(num) => setPage(num)}
                                lang={lang}
                                callCookies={(bool) => callCookies(bool)}
                            />
                        )}
                    </>
                )}
            </>
        </Pagestyle>
    );
};
export default News;
